/** @format */

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
	profileImg1,
	profileImg2,
	profileImg3,
} from "../../dashboard/src/assets";
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

const data = [
	{
		id: 1,
		profileImg: profileImg1,
		name: "Isobel Rhodes",
		status: "Online",
	},
	{
		id: 2,
		profileImg: profileImg2,
		name: "Gail Jones",
		status: "Online",
	},
	{
		id: 3,
		profileImg: profileImg3,
		name: "Dianne Kelley",
		status: "Online",
	},
	{
		id: 4,
		profileImg: profileImg2,
		name: "Joel Martinez",
		status: "Online",
	},
	{
		id: 5,
		profileImg: profileImg1,
		name: "Armando Carpenter",
		status: "Online",
	},
	{
		id: 6,
		profileImg: profileImg1,
		name: "Isobel Rhodes",
		status: "Online",
	},
	{
		id: 7,
		profileImg: profileImg2,
		name: "Gail Jones",
		status: "Online",
	},
	{
		id: 8,
		profileImg: profileImg3,
		name: "Dianne Kelley",
		status: "Online",
	},
	{
		id: 9,
		profileImg: profileImg2,
		name: "Joel Martinez",
		status: "Online",
	},
	{
		id: 10,
		profileImg: profileImg1,
		name: "Armando Carpenter",
		status: "Online",
	},
	{
		id: 11,
		profileImg: profileImg1,
		name: "Isobel Rhodes",
		status: "Online",
	},
	{
		id: 12,
		profileImg: profileImg2,
		name: "Gail Jones",
		status: "Online",
	},
	{
		id: 13,
		profileImg: profileImg3,
		name: "Dianne Kelley",
		status: "Online",
	},
	{
		id: 14,
		profileImg: profileImg2,
		name: "Joel Martinez",
		status: "Online",
	},
	{
		id: 15,
		profileImg: profileImg1,
		name: "Armando Carpenter",
		status: "Online",
	},
];

export interface Props {
	navigation?: any;
	id?: string;
	// Customizable Area Start
	// Customizable Area End
}

interface S {
	txtInputValue: string;
	txtSavedValue: string;
	enableField: boolean;
	tabValue: string;
	userData: any;
	postModalVisible: boolean;
	currentValue: string;
	// Customizable Area Start
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class ActivityFeedController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	uploadImgRef: any;
	messagesEndRef: any;
	textareaRef: any;

	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

		this.state = {
			txtInputValue: "",
			txtSavedValue: "A",
			enableField: false,
			tabValue: "activity",
			userData: data,
			postModalVisible: false,
			currentValue: "",
		};

		this.messagesEndRef = React.createRef();
		this.textareaRef = React.createRef();

		this.uploadImgRef = React.createRef();

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// Customizable Area End
	}

	// Customizable Area Start
	async receive(from: string, message: Message) {
		runEngine.debugLog("Message Recived", message);

		if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
			let value = message.getData(
				getName(MessageEnum.AuthTokenDataMessage)
			);

			this.showAlert(
				"Change Value",
				"From: " + this.state.txtSavedValue + " To: " + value
			);

			this.setState({ txtSavedValue: value });
		}
	}

	txtInputWebProps = {
		onChangeText: (text: string) => {
			this.setState({ txtInputValue: text });
		},
		secureTextEntry: false,
	};
	handleCloseModel = () => {
		this.setState({ postModalVisible: false });
	};
	handleOpen = () => {
		this.setState({ postModalVisible: true });
	};
	handleChangeTextArea=(e:any)=> {
		this.setState({ currentValue: e.target.value })

		this.textareaRef.current.style.height = "0px";
		const scrollHeight = this.textareaRef.current.scrollHeight;
		this.textareaRef.current.style.height = scrollHeight + "px";
	}
		

	
	txtInputMobileProps = {
		...this.txtInputWebProps,
		autoCompleteType: "email",
		keyboardType: "email-address",
	};

	txtInputProps = this.isPlatformWeb()
		? this.txtInputWebProps
		: this.txtInputMobileProps;

	btnShowHideProps = {
		onPress: () => {
			this.setState({ enableField: !this.state.enableField });
			this.txtInputProps.secureTextEntry = !this.state.enableField;
			this.btnShowHideImageProps.source = this.txtInputProps
				.secureTextEntry
				? imgPasswordVisible
				: imgPasswordInVisible;
		},
	};

	btnShowHideImageProps = {
		source: this.txtInputProps.secureTextEntry
			? imgPasswordVisible
			: imgPasswordInVisible,
	};

	btnExampleProps = {
		onPress: () => this.doButtonPressed(),
	};

	doButtonPressed() {
		let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
		msg.addData(
			getName(MessageEnum.AuthTokenDataMessage),
			this.state.txtInputValue
		);
		this.send(msg);
	}

	// web events
	setInputValue = (text: string) => {
		this.setState({ txtInputValue: text });
	};

	setEnableField = () => {
		this.setState({ enableField: !this.state.enableField });
	};
	// Customizable Area End
}
// Customizable Area End
