/** @format */

import React from "react";

import {
	Box,
	Button,
	Typography,
	// Customizable Area Start
	Grid,
	Modal,
	// Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Navbar from "../../dashboard/src/Navbar.web";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import {
	activityImage,
	activitySelected,
	chatImage,
	chatSelected,
	clip,
	closeImage,
	globe,
	location,
	smile,
	userImage,
	userSelected,
	whitePlus,
} from "./assets";
import { profileImg1 } from "../../dashboard/src/assets";
import ChatMainComponent from "./ChatMainComponent.web";
import FeedTab from "./FeedTab.web";
import ThemeContext from "../../../components/src/ThemeContext.web";
import UserActivity from "./UserActivity.web";
// Customizable Area End

import ActivityFeedController, { Props } from "./ActivityFeedController";

export default class ActivityFeed extends ActivityFeedController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	renderScreen = () => {
		switch (this.state.tabValue) {
			case "activity":
				return <FeedTab navigation={this.props.navigation} />;
			case "chat":
				return <ChatMainComponent navigation={this.props.navigation} />;
			case "user":
				return <UserActivity navigation={this.props.navigation} />;
			default:
				return <FeedTab navigation={this.props.navigation} id={""} />;
		}
	};
	// Customizable Area End

	render() {
		// Customizable Area Start
		const { tabValue, postModalVisible } = this.state;
		// Customizable Area End
		return (
			// Customizable Area Start
			<>
				<DashboardSidebar
					context={this.context}
					history={this.props.navigation}
					selected="Activity"
				/>
				<div style={{ flex: 1, height: "100%" }}>
					<Grid container className="main-container app-page-dashboard">
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}
							className="container-items">
							<Navbar title="Welcome" navigation={this.props.navigation} context={this.context} />

							<div className="activity-feed-container">
								<div className="tab-top-bar-bg">
									<div
										style={{
											flex: 1,
											backgroundColor:
												tabValue == "activity" ? "blue" : "transparent",
										}}
										data-test-id={"onActivityTabClick"}
										onClick={() => {
											this.setState({ tabValue: "activity" });
										}}
										className={
											tabValue == "activity"
												? "selected-tab"
												: "unselected-tab"
										}>
										<img
											className="item-img"
											style={{ height: 20, width: 20, color: "white" }}
											src={
												tabValue == "activity"
													? activitySelected
													: activityImage
											}
										/>

										{tabValue == "activity" && (
											<h4 style={{ marginLeft: 5, color: "white" }}>
												Activity
											</h4>
										)}
									</div>

									<div
										style={{
											flex: 1,
											backgroundColor:
												tabValue == "chat" ? "blue" : "transparent",
										}}
										data-test-id={"onChatTabClick"}
										onClick={() => {
											this.setState({ tabValue: "chat" });
										}}
										className={
											tabValue == "chat"
												? "selected-tab"
												: "unselected-tab"
										}>
										<img
											style={{ height: 20, width: 20 }}
											src={tabValue == "chat" ? chatSelected : chatImage}
										/>
										{tabValue == "chat" && (
											<h4 style={{ marginLeft: 5, color: "white" }}>
												Chat
											</h4>
										)}
									</div>

									<div
										style={{
											flex: 1,
											backgroundColor:
												tabValue == "user" ? "blue" : "transparent",
										}}
										data-test-id={"onUserTabClick"}
										onClick={() => {
											this.setState({ tabValue: "user" });
										}}
										className={
											tabValue == "user"
												? "selected-tab"
												: "unselected-tab"
										}>
										<img
											style={{ height: 20, width: 20 }}
											src={tabValue == "user" ? userSelected : userImage}
										/>
										{tabValue == "user" && (
											<h4 style={{ marginLeft: 5, color: "white" }}>
												User
											</h4>
										)}
									</div>
								</div>
							</div>
							{this.renderScreen()}
						</Grid>

						{tabValue == "activity" && (
							<div
								data-test-id={"onCreatePostCLick"}
								onClick={this.handleOpen}
								className="create-post-class">
								<div
									style={{ alignItems: "center", justifyItems: "center" }}
									className="plus-button">
									<img
										style={{ height: 30, width: 30 }}
										src={whitePlus}
										alt={"like"}
									/>
								</div>
								<h5 style={{ marginTop: 4 }}>Create post</h5>
							</div>
						)}
					</Grid>
				</div>

				<Modal
					open={postModalVisible}
					onClose={this.handleCloseModel}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description">
					<Box sx={webStyle.mainWrapper}>
						<Box sx={webStyle.modalWrapper}>
							<Typography
								style={{
									fontWeight: "bold",
									color: "#1D1350",
									alignSelf: "center",
									marginBottom: 15,
									fontSize: 28,
								}}
								className="modal-title"
								id="modal-modal-title"
								variant="h6"
								component="h2">
								Create Post
							</Typography>

							<div
								style={{
									width: "100%",
									height: 1,
									backgroundColor: "#ADADAD60",
									marginTop: 15,
									marginBottom: 25,
								}}
							/>

							<div
								style={{ width: "95%", alignSelf: "center" }}
								className="user-detail-card">
								<div className="chat-card-sec card-chat-detail">
									<div className="profile-img-container">
										<img
											style={{ height: 50, width: 50 }}
											src={profileImg1}
											alt={"Clara Jensen"}
										/>
									</div>
									<div className="profile-detail">
										<h3 style={{ fontSize: 18 }}>{"Clara Jensen"}</h3>
										<div
											style={{
												marginTop: 6,
												alignItems: "center",
												justifyContent: "flex-start",
												backgroundColor: "#EBEBEB",
												padding: 5,
												paddingRight: 0,
												borderRadius: 6,
											}}
											className="public-row-class">
											<div
												style={{
													alignItems: "center",
													justifyItems: "center",
												}}>
												<img
													style={{
														height: 20,
														width: 20,
														marginRight: 10,
													}}
													src={globe}
												/>
											</div>
											<p style={{ fontWeight: "bold" }}>{"Public"}</p>
										</div>
									</div>
								</div>
							</div>

							<Typography
								style={{
									width: "95%",
									marginTop: 20,
									fontWeight: "bold",
									textAlign: "left",
									color: "#000000",
									alignSelf: "center",
									marginBottom: 30,
									fontSize: 28,
									opacity: 0.2,
								}}
								className="modal-title"
								id="modal-modal-title"
								variant="h6"
								component="h2">
								What’s on your mind, Clara?
							</Typography>

							<div
								style={{
									marginTop: 6,
									alignItems: "center",
									justifyContent: "flex-start",
									width: "95%",
								}}
								className="public-row-class">
								<div
									style={{ alignItems: "center", justifyItems: "center" }}>
									<img
										style={{ height: 20, width: 20, marginRight: 10 }}
										src={location}
									/>
								</div>
								<p style={{}}>{"at Park City, Utah"}</p>
							</div>
							<div
								style={{ width: "95%", position: "relative" }}
								className="add-post-class">
								<input
									type={"text"}
									onChange={() => { }}
									placeholder="Add to your post"
									className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ""
										}`}
								/>
								<div
									onClick={() => {
										this.uploadImgRef.current.click();
									}}
									className="input-field-clip-buttons"
									style={{ alignItems: "center", justifyItems: "center" }}>
									<img
										style={{ height: 27, width: 27, marginRight: 10 }}
										src={clip}
									/>
								</div>
								<div
									className="input-field-emoji-buttons"
									style={{ alignItems: "center", justifyItems: "center" }}>
									<input
										ref={this.uploadImgRef}
										style={{ display: "none" }}
										type="file"
										accept="image/*"
										name="image-upload"
										id="input"
										data-test-id="file-input-handler"
									/>
									<img
										style={{ height: 27, width: 27, marginRight: 10 }}
										src={smile}
									/>
								</div>
							</div>
							<Grid container>
								<Grid item xs={12}>
									<div className="app--create-post-form-field-button">
										<Button
											className={`app-create-post-form-button ${this.context.isDark ? "btn-color color-white" : ""
												}`}
											variant="contained"
											style={{
												width: "95%",
												alignSelf: "center",
												borderRadius: 21,
												marginTop: 20,
												marginBottom: 10,
											}}
											onClick={() => { }}>
											Post
										</Button>
									</div>
								</Grid>
							</Grid>

							<div
								className="pointer-class"
								onClick={this.handleCloseModel}
								style={{
									alignItems: "center",
									justifyItems: "center",
									position: "absolute",
									right: 30,
									top: 30,
								}}>
								<img
									style={{ height: 20, width: 20, marginRight: 10 }}
									src={closeImage}
								/>
							</div>
						</Box>
					</Box>
				</Modal>
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
const webStyle = {
	mainWrapper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		width: "100%",
		backgroundColor: "transparent",
		alignSelf: "center",
	},
	modalWrapper: {
		backgroundColor: "white",
		width: "50%",
		padding: 15,
		position: "relative",
		borderRadius: 38,
		boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)",
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
	},
};

ActivityFeed.contextType = ThemeContext;
// Customizable Area End
