/** @format */

import React from "react";
// Customizable Area Start
import DashboardControllerWeb,{Props} from "./DashboardControllerWeb";
import VideogameAssetIcon from "@material-ui/icons/VideogameAsset";
import ChatIcon from "@material-ui/icons/Chat";
import "./ChatBoxCard.scss";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class ChatBoxCard extends DashboardControllerWeb {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	  }
	render() {
		return (
			<div className="chat-card">
				<div className="chat-card-sec">
					<div className="profile-img-container">
						<img
							src={this.props.item.profileImg}
							alt={this.props.item.name}
						/>
						<span className="dots" />
					</div>
					<div className="profile-detail">
						<h3>{this.props.item.name}</h3>
						<p>{this.props.item.status}</p>
					</div>
				</div>
				<div className="icon-button">
					<VideogameAssetIcon className="videogame-icon" />
					<ChatIcon className="chat-icon" />
				</div>
			</div>
		);
	}
}
export default ChatBoxCard;

// Customizable Area End
