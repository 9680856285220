import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { createRef } from "react";

import {countriesCode} from "./CountryData"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  security_number: any;
  confirm_security_number: any;
  phone: string;
  username: string;
  activated: boolean;
  title: any;
  date: any;
  errorMessage:any;errorsList:any;
  darkMode: boolean;
  checkData: boolean;
  maxDate:string;
  showsnack:boolean;
  countryCodeData: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
 // Customizable Area Start
export default class EmailRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: any;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  dateInputRef: any = null;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      activated: true,
      username: "",
      errorMessage:"",
      title: "Mr",
      date: "",
      errorsList:[],
      darkMode: false,
      security_number: "",
      confirm_security_number: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      checkData: false,
      maxDate: '',
      showsnack:false,
      countryCodeData: countriesCode
      // Customizable Area End
    };

    // Customizable Area Start
    this.dateInputRef = createRef();
    this.arrayholder = [];
  
    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;

    // Customizable Area End
  }


  componentDidMount(): any {
    // Customizable Area Start
    this.maxDateFunc()
    // Customizable Area End  

}
  onResponceReceived = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createAccountApiCallId) {

        if (responseJson?.meta?.token) {
          runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          localStorage.setItem("accessToken",responseJson.meta.token)
          localStorage.setItem("entered","true")
          this.props.navigation.navigate("LockScreen")
          console.log("responseJson",responseJson)
        } else if(responseJson?.errors){
          this.setState({
            errorsList:responseJson?.errors
          })
          console.log("responseJson error",responseJson)
        }else {
          this.setState({errorMessage:"Something went wrong"})
          //Check Error Response
          this.parseApiErrorResponse(responseJson);
         
        }

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.onResponceReceived(apiRequestCallId,responseJson,errorReponse)
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  maxDateFunc = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    this.setState({ maxDate: `${yyyy}-${mm}-${dd}` });
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleClose=()=> {
    this.setState({showsnack: false});
    }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  handleUserEmail = (e: any) => {
    let errorsData = [...this.state.errorsList]
    if (
      e.target.value.length
      ) {
        errorsData.push({account: ''});
      }
      this.setState({ email: e.target.value, errorsList: errorsData });
  };

  handleUserChecked = (e: any) => {
    this.setState({ checkData: e.target.checked });
  };

  handleUserPassword = (e: any) => {
    this.setState({ password: e.target.value });
  };
  handleUserName = (e: any) => {
    this.setState({ username: e.target.value });
  };
  handleUserDateOfBirth = (e: any) => {
    this.setState({ date: e.target.value });
    this.dateInputRef.current.blur();
  };
  handleUserTitle = (e: any) => {
    this.setState({ title: e.target.value });
  };
  handleUserFirstName = (e: any) => {
    this.setState({ firstName: e.target.value });
  };
  handleUserLastName = (e: any) => {
    this.setState({ lastName: e.target.value });
  };
  handleUserMobileNum = (e: any) => {
    this.setState({ phone: e.target.value });
  };
  handleCountryCode = (e: any) => {
    console.log(e.target.value)
    this.setState({ countryCodeSelected: e.target.value });
  };
  handleSecurityCode = (e: any) => {
    let num = e.target.value.slice(0,8);
    this.setState({ security_number: num });
  };
  handleConfirmSecurityCode = (e: any) => {
    let num = e.target.value.slice(0,8);
    this.setState({ confirm_security_number: num });
  };

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  createAccount() {
    let decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
   let errorsData = []
    if (
      this.state.email === null ||
      this.state.email.length === 0 
    ) {
      errorsData.push({account:configJSON.errorEmailNotValid})
    }
    if(this.state.firstName == ""){
      errorsData.push({first_name:"Please enter name first"})
    }
    if(this.state.lastName == ""){

      errorsData.push({last_name:"Please enter name last"})
    }
    if(this.state.phone == ""){
      errorsData.push({full_phone_number:"Please enter valid phone numbers"})
    }
    if(this.state.username == ""){
      errorsData.push({username:"Please enter username"})
    }
    if(this.state.password == ""){
      errorsData.push({pass_code:"Please enter password"})
    }
    if(!this.state.password.match(decimal)){
      errorsData.push({pass_code:"Password should contain 1 special charecter,1 uppercase,1 lowercase and 1 digit"})
    }
    if(this.state.security_number == ""){

      errorsData.push({security_number:"Please enter Security numbers"})
    }

    if(this.state.security_number.length != 4){
      errorsData.push({security_number:"Security pin must be at least 4 digits"})
    }

    if(this.state.confirm_security_number == ""){

      errorsData.push({confirm_security_number:"Please confirm security numbers"})
    }
    if(this.state.confirm_security_number != this.state.security_number){

      errorsData.push({confirm_security_number:"security numbers is different from confirm security number"})
    }
    if(this.state.countryCodeSelected == ""){

      errorsData.push({countryCodeSelected:"Please select your country"})
    }
    if(this.state.date == ""){

      errorsData.push({date:"Please enter date"})
    }
    if(this.state.title == ""){

      errorsData.push({title:"Please enter title"})
    }
    if(!this.state.checkData){
      this.setState({showsnack:true})
      return;
    }

if(errorsData.length>0){
  this.setState({errorsList:errorsData})
  return
}

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      "email": this.state.email,
      "password": this.state.password,
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "phone_number": "",
      "user_name": this.state.username,
      "country": "india",
      "country_code": "+91",
      "security_number": this.state.security_number,
      "confirm_security_number": this.state.confirm_security_number,
      "date_of_birth": this.state.date,
      "status": (this.state.title == "Mr" ? 1 : 0),
      "full_phone_number": this.state.phone,
      "activated": true,
    };
    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: any) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  darkModeHandler = () => {
     this.setState(prev => {
      return {
        ...prev,
        darkMode: !prev.darkMode
      }
     });
  }

  onPressDarkMode = {
    onPress: () => this.darkModeHandler()
  }

  // Customizable Area End
}
// Customizable Area End