Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Avatar";
exports.labelBodyText = "Avatar Body";

exports.btnExampleTitle = "CLICK ME";

exports.selectSkinTitle = "Select Skin";
exports.selectHairTitle = "Select hair and color";
exports.selectFacialHairTitle = "Select facial hair and color";
exports.selectBodyTitle = "Select body and color";
exports.selectEyesTitle = "Select eyes";
exports.selectMouthTitle = "Select Mouth";
exports.selectNoseTitle = "Select nose";
exports.selectBgcTitle = "Select background color";
exports.randomBtnTitle = "Random Character"
exports.saveBtnTitle = "Save";
// Customizable Area End