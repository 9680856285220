// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  context?:any
  sendProfileData?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profileImg: any;
  previewUploadFile: string;
  isChange: boolean;
  open:boolean;
  profileData:{
    first_name: string,
    last_name: string,
    user_name: string,
    profile_pic: {
      url:string
    }
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
export default class PublicProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  updateProfileDetailCallId: string = "";
  publicProfileDetailCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      open:false,
      profileData:{
        first_name: "",
        last_name: "",
        user_name: "",

        profile_pic: {
          url:""
        }
      },
      isChange: false,
      profileImg:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
      previewUploadFile: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getPublicProfileDetail()
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const token = localStorage.getItem("accessToken");
    return token;
  };
  handleClose=()=> {
    this.setState({open: false});
    }
  handleChangePublicProfile = (e: any) => {
    this.setState({ profileData: {
        ...this.state.profileData,
      [e.target.name]: e.target.value,
      
    },
     
    });
    if (e.target.files && e.target.files[0]) {
      this.setState({
        profileImg: e.target.files[0],
        profileData: {
          ...this.state.profileData,
          profile_pic: {
            url:URL.createObjectURL(e.target.files[0])
          }
        },
        isChange:true
      });
    }
  };

  resetFunc = () => {
    this.setState({
      profileData: {
        ...this.state.profileData,
        first_name: '',
        last_name: '',
        user_name: '',
      },
    });
  };

  updateProfileDetail = () => {

    let headers = {
      token: this.getToken()

    };
    let formData = new FormData();
    const firstName = this.state.profileData?.first_name
    const lastName = this.state.profileData?.last_name
    const username = this.state.profileData?.user_name
    const profileImgage = this.state.isChange === true? this.state.profileImg :""
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)
    formData.append("user_name", username)

    formData.append("profile_pic", profileImgage)

    const updateProfileDataMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileDetailCallId = updateProfileDataMessage.messageId;

    updateProfileDataMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileApiEndPoint
    );

    updateProfileDataMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    updateProfileDataMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    updateProfileDataMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethodType
    );
    runEngine.sendMessage(updateProfileDataMessage.id, updateProfileDataMessage);
  }

 
  getPublicProfileDetail = () => {
    const headers = {
        "Content-Type": configJSON.dashboarContentType,
        token:  this.getToken()
    };

    const profileDataMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.publicProfileDetailCallId = profileDataMessage.messageId;

    profileDataMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileDetailApiEndPoint
    );

    profileDataMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    profileDataMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.profileDetailApiMethodType
    );

    runEngine.sendMessage(profileDataMessage.id, profileDataMessage);
}



  handleResponseToUpdateProfileDetail = (from: string, message: Message) => {
    if (this.updateProfileDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiResponse) {
        this.setState({ open: true })
        this.props.sendProfileData(this.state.profileData);
      }

    }
  }
  handleResponseForPublicProfileDetail = (from: string, message: Message) => {     
    if (this.publicProfileDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponse) {
        this.setState({profileData: apiResponse.data.attributes});
        
        } 
    }
}


  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResponseToUpdateProfileDetail(from, message)
    this.handleResponseForPublicProfileDetail(from,message)
    // Customizable Area End
  }
}
// Customizable Area End
