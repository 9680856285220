/** @format */

import React from "react";
// Customizable Area Start
import { Button, Typography } from "@material-ui/core";
import { scobbyImg, beastImg } from "./assets";
import GameCard from "./GameCard.web";
import DashboardControllerWeb, { Props } from "./DashboardControllerWeb";
// Customizable Area End

// Customizable Area Start
export interface GameDataProps {
	id: number;
	image: string;
	desc: string;
}
const gameData = [
	{
		id: 1,
		image: scobbyImg,
		desc: "Lorem Ipsum is simply dummy text",
	},
	{
		id: 2,
		image: beastImg,
		desc: "Lorem Ipsum is simply dummy text",
	},
];
// Customizable Area End

// Customizable Area Start
class GameSection extends DashboardControllerWeb {
	constructor(props: Props) {
		super(props);
	}
	render() {
		return (
			<div className="game-sec">
				<div className="nav-header-game">
					<Typography className={`activity ${this.props.context.isDark ? "activity-dark" :""}`} variant="h5">
						Quickstart games
					</Typography>
					<Button className={` ${this.props.context.isDark ? "color-white" :""}`}>View all</Button>
				</div>
				<div className="game-item">
					{gameData.map((item) => (
						<React.Fragment key={item.id}>
							<GameCard context={this.props.context}  item={item} />
						</React.Fragment>
					))}
				</div>
			</div>
		);
	}
}
export default GameSection;

// Customizable Area End
