// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  open?: boolean;
  onClose?: any;
  context?:any;
  data: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  isPlaying: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class NavbarDropdownController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  audioRef: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      isPlaying: false
    };
    this.audioRef = createRef();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  logOut = () => {
    this.handlePlayPause();
    localStorage.clear();
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };
  onSettingClick = () => {
    this.props.navigation.history.push("setting");
  };




  // Customizable Area End  

  handlePlayPause = () => {
    if (this.audioRef.current) {
      if (this.state.isPlaying) {
        this.audioRef.current.pause();
        this.setState({ isPlaying: false });
      } else {
        this.audioRef.current.play();
        this.setState({ isPlaying: true });
      }
    }
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
