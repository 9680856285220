import React from "react";
// Customizable Area Start
import Button from "@material-ui/core/Button";
import "./EmailAccountLoginBlock.scss";
import EmailLoginAccountController, {
	Props,
} from "./LoginEmailAccountController";
import Sidebar from "../../../components/src/Sidebar.web";
import ThemeContext from '../../../components/src/ThemeContext.web';

// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default class EmailAccountLogin extends EmailLoginAccountController {


	constructor(props: Props) {
		super(props);
      
		// Customizable Area Start
		// Customizable Area End
	}

  render() {
    // console.log("props",this.context)
    return (
      // Required for all blocks
      <>
       <Sidebar  from="login" history={this.props.navigation} context={this.context}/>
           <div className={`app-page-section ${this.context.isDark ? "dark-color" :""}`}>
               <div className="app-page-wrapper">
                   <div className="app-login-form">
                       <h1 className={`app-login-form-title ${this.context.isDark ? "color-white" :""}`}>Login</h1>
                      
                       
                       <form>
                           <ul className="app-login-form-field-group custom">
                               <li>
                                   <input className={`app-login-form-field ${this.context.isDark? "textfield-color":''}`} placeholder="Email" onChange={(e:any) => {this.setEmail(e.target.value)}} value={this.state.email} />
                               </li>

                               <li>
                                   <input className={`app-login-form-field ${this.context.isDark? "textfield-color":''}`} value={this.state.password} onChange={(e:any) => {this.setPassword(e.target.value)}}  type={'password'} placeholder={this.state.login_with_pin ? 'pin': 'password'} />
                               </li>
                               {this.state.errorMessage && <span className="text-center error-text">{this.state.errorMessage}</span>}
                               <div className="login_with_pin">
                                        <span className={`login_or ${this.context.isDark ? "color-white" :""}`} >or</span>
                                        <span  className={`login_password ${this.context.isDark ? "color-white" :""}`} onClick={() => {this.changeLoginWithPin()}}>Login with {!this.state.login_with_pin ? 'pin': 'password'}</span>
                                </div>
                           </ul>
                          <Button className={`app-login-form-button ${this.context.isDark? "btn-color color-white":''}`} variant="contained" onClick={this.btnEmailLogInProps.onPress} disabled={this.state.loading}>{this.state.loading ? 'Loading....' :'Next'}</Button>
                     <p className={`app-login-form-button-alt cursor-pointer ${this.context.isDark ? "color-white" :""}`}  onClick={() => {
                             this.props.navigation.navigate("ForgotPassword")  
                            }}>Having trouble logging in?</p>
                       </form>
                   </div>                    
               </div>
           </div>
   </>
    );
  }
}


EmailAccountLogin.contextType = ThemeContext;
// Customizable Area End