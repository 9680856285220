// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  test?: any;
  context:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currency: string,
  currencyList: any,
  selectedCurrency: any,
  openCurrencyToast: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class CurrencyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiChangeApperanceSettings: string = "";
  getApperanceApiId: string = "";
  getCurrancyApiId: string = "";
  apiRequestApperanceCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      currency: "",
      currencyList: ["USD"],
      selectedCurrency: "",
      openCurrencyToast:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getCurrencySettings();
    this.getCurrentSettings();
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const token = localStorage.getItem("accessToken");
    return token;
  };
  handleCloseCurrencyToast=()=> {
    this.setState({openCurrencyToast: false});
    }
  setCurrency = (value:any) => {
    this.setState({selectedCurrency: value})
    this.handleCurrencysettings(value)
  }

  getCurrencySettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApperanceApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrencySettingApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodForSoundSetting
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  getCurrentSettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrancyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSoundSettingApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodForSoundSetting
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCurrencysettings = (key: string) => {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };

    const attrs = {
      "currency":key
     } 

    const data = {
      "attributes": attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangeApperanceSettings = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeCurrancySettingApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleChangeApperanceSettingResponse = (from: string, message: Message) => {
    const apiRequestApperanceCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestApperanceCallId != null) {
      if (
        apiRequestApperanceCallId === this.apiChangeApperanceSettings &&
        responseJson !== undefined
      ) {
        this.setState({openCurrencyToast:true})
      }
    }
  }

  handleGetApperanceSettingsResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.getApperanceApiId &&
        responseJson !== undefined
      ) {
        
          this.setState({currencyList: responseJson.currencies_list})
      }
    }
  }

  handleGetCurrancySettingsResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.getCurrancyApiId &&
        responseJson !== undefined
      ) {
        this.setState({selectedCurrency:responseJson.data?.attributes?.current_currency})
      }
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleChangeApperanceSettingResponse(from, message);
    this.handleGetApperanceSettingsResponse(from, message);
    this.handleGetCurrancySettingsResponse(from, message)
    // Customizable Area End
  }
}
// Customizable Area End
