/** @format */

import React from "react";
// Customizable Area Start
import { Button, Typography } from "@material-ui/core";
import { imageCube } from "./assets";

// Customizable Area End

// Customizable Area Start
import DashboardControllerWeb, { Props } from "./DashboardControllerWeb";
// Customizable Area End

// Customizable Area Start
class DashboardContent extends DashboardControllerWeb {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	render() {
		return (
			<div className={`dashboard-content ${this.props.context.isDark ? "dark-side-color" :""}`}>
				<div className="content-inner">
					<Typography className="content-heading" variant="h4">
						Discover, Collect and Sell NFTs
					</Typography>
					<div className="button-group">
						<Button className="nft-button">NFT Marketplace</Button>
						<Button className="gamestore-button">Game Stores</Button>
					</div>
				</div>
				<div className="image-cube">
					<img src={imageCube} alt="cube" />
				</div>
			</div>
		);
	}
}
export default DashboardContent;

// Customizable Area End
