// Customizable Area Start
import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { esfera_logo } from '../../blocks/email-account-login/src/assets';
import DarkModeSwitch from './DarkModeSwitch.web';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
const Sidebar = ({from,history, context}:any) => {
  useEffect(() => {
    if(localStorage.getItem('darkMode') == "true"){
      context?.updateDarkMode(true);
    }else{
      context?.updateDarkMode(false);
    }
  },[])
  return (
    <div className="app-sidebar">
        <div className="app-sidebar-logo">
          <img src={esfera_logo} />
        </div>
        <div className="app-sidebar-menu">
          <ul>
            <li>
              <Button className={`app-sidebar-menu-button ${from == 'login' ? 'active' : ''} ${context.isDark ? 'dark-color':''}`} onClick={() => {
                history.navigate("EmailAccountLoginBlock")
              }}>
                Login
                <div className={` ${context.isDark ?'inverted-curve-dark' : 'inverted-curve'} `}></div>
              </Button>
            </li>
            <li>

              <Button className={`app-sidebar-menu-button ${from == 'register' ? 'active' : ''} ${context.isDark ? 'dark-color':''}`} onClick={() => {
                history.navigate("EmailAccountRegistration")
              }} >
                Signup
                <div className={` ${context.isDark ?'inverted-curve-dark' : 'inverted-curve'} `}></div>
              </Button>

            </li>
          </ul>
        </div>
        <div className='dark-mode-btn-wrapper'>
            <DarkModeSwitch context={context}/>
        </div>
      </div>
  )
}

export default Sidebar;

// Customizable Area End
