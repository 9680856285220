import React from "react";

// Customizable Area Start
import {
  SafeAreaView,
  Dimensions,
  PixelRatio,
  View,
  Text,
  FlatList,
  SectionList,
  StyleSheet,
  Button,
  TouchableOpacity,
  CheckBox,
  Switch,
  Platform,
  Image,
  TextInput,
  Picker,
  ActivityIndicator,
  Alert,
  ImageBackground,
  ScrollView,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import {
  responsiveHeight,
  responsiveWidth,
  responsiveFontSize,
} from "react-native-responsive-dimensions";
import { TabView, SceneMap, TabBar } from "react-native-tab-view";
import RadioForm, {
  RadioButton,
  RadioButtonInput,
} from "react-native-simple-radio-button";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";

//@ts-ignore
import CustomCheckBox from "../../../components/src/CustomCheckBox";

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { leftArrow, rightArrow } from "./assets";
import Skin from "./personas-avatar/components/skin";
import Hair from "./personas-avatar/components/hair";
import FacialHair from "./personas-avatar/components/facial-hair";
import Body from "./personas-avatar/components/body";
import Eyes from "./personas-avatar/components/eyes";
import Mouth from "./personas-avatar/components/mouth";
import Nose from "./personas-avatar/components/nose";
import Svg from "react-native-svg";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  title: string;
  icons?: any;
  colors?: any;
  selectedIcon?: any;
  selectedColor?: any;
  onIconPress?: any;
  onColorPress?: any;
  type: string;
  open: string | null;
  openToggle: Function;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  currentXOffset: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SelectOptionsAvatar extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  svgRef: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      currentXOffset: 0,
      // Customizable Area End
    };

    // Customizable Area Start
    this.svgRef = React.createRef();

    Dimensions.addEventListener("change", (e) => {
      MergeEngineUtilities.init(
        artBoardHeightOrg,
        artBoardWidthOrg,
        Dimensions.get("window").height,
        Dimensions.get("window").width
      );
      this.forceUpdate();
    });
    // Customizable Area End
  }

  // Customizable Area Start
  rightArrow = () => {
    this.svgRef.scrollTo({
      x: this.state.currentXOffset + 200,
      animated: true,
    });
  };

  leftArrow = () => {
    this.svgRef.scrollTo({
      x: this.state.currentXOffset - 200,
      animated: true,
    });
  };

  handleScroll = (event: any) => {
    this.setState({
      currentXOffset: event.nativeEvent.contentOffset.x,
    });
  };

  renderSkinColors = () => {
    return (
      <View style={styles.selectorWrapper}>
        <TouchableOpacity
          style={styles.titleWrapper}
          onPress={() =>
            this.props.openToggle(
              this.props.open === this.props.type ? null : this.props.type
            )
          }
        >
          <Text style={styles.selectorTitle}>{this.props.title}</Text>
          <Image
            source={rightArrow}
            style={
              this.props.open === this.props.type
                ? styles.collapseIconActive
                : styles.collapseIcon
            }
          />
        </TouchableOpacity>
        {this.props.open === this.props.type && (
          <View style={styles.selectorInnerWrapper}>
            <Svg width="80px" height="80px" viewBox="0 0 64 64">
              <Skin color={this.props.colors[this.props.selectedColor]} />
            </Svg>
            <View style={styles.selectorContent}>
              {Object.entries(this.props.colors).map(
                (skin: any, index: number) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      onPress={() => this.props.onColorPress(skin[0])}
                      style={[styles.colors, { backgroundColor: skin[1] }]}
                    />
                  );
                }
              )}
            </View>
          </View>
        )}
      </View>
    );
  };
  // Customizable Area End

  render = () => {
    // Customizable Area Start
    // Merge Engine - render - Start
    if (this.props.type === "skinColors") {
      return this.renderSkinColors();
    }
    return (
      <View style={styles.selectorWrapper}>
        <TouchableOpacity
          style={styles.titleWrapper}
          onPress={() =>
            this.props.openToggle(
              this.props.open === this.props.type ? null : this.props.type
            )
          }
        >
          <Text style={styles.selectorTitle}>{this.props.title}</Text>
          <Image
            source={rightArrow}
            style={
              this.props.open === this.props.type
                ? styles.collapseIconActive
                : styles.collapseIcon
            }
          />
        </TouchableOpacity>
        {this.props.open === this.props.type && (
          <View style={styles.selectorInnerWrapper}>
            <View style={styles.hairSelectorContent}>
              {this.props.icons && (
                <View
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{ alignItems: "flex-start", borderRadius: 30 }}
                    onPress={() => this.leftArrow()}
                  >
                    <Image
                      source={leftArrow}
                      style={{ width: 30, height: 30 }}
                    />
                  </TouchableOpacity>
                  <ScrollView
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      overflow: "hidden",
                      paddingBottom: 15,
                    }}
                    horizontal={true}
                    ref={(c) => (this.svgRef = c)}
                    onScroll={this.handleScroll}
                    scrollEventThrottle={16}
                  >
                    {this.props.icons &&
                      Object.entries(this.props.icons).map(
                        (icon: any, index: number) => {
                          return (
                            <TouchableOpacity
                              key={index}
                              style={{
                                backgroundColor:
                                  icon[0] === this.props.selectedIcon
                                    ? "#DCDCDC"
                                    : "#f8f8f8",
                                margin: 5,
                                borderRadius: 5,
                              }}
                              onPress={() => this.props.onIconPress(icon[0])}
                            >
                              <Svg
                                width="80px"
                                height="80px"
                                viewBox="0 0 64 64"
                              >
                                {this.props.type === "hairs" && (
                                  <Hair
                                    value={icon[0]}
                                    color={
                                      this.props.colors[
                                        this.props.selectedColor
                                      ]
                                    }
                                  />
                                )}
                                {this.props.type === "facialHairs" && (
                                  <FacialHair
                                    value={icon[0]}
                                    color={
                                      this.props.colors[
                                        this.props.selectedColor
                                      ]
                                    }
                                  />
                                )}
                                {this.props.type === "body" && (
                                  <Body
                                    value={icon[0]}
                                    color={
                                      this.props.colors[
                                        this.props.selectedColor
                                      ]
                                    }
                                  />
                                )}
                                {this.props.type === "eyes" && (
                                  <Eyes value={icon[0]} />
                                )}
                                {this.props.type === "mouths" && (
                                  <Mouth value={icon[0]} />
                                )}
                                {this.props.type === "noses" && (
                                  <Nose
                                    value={icon[0]}
                                    color={this.props.selectedColor}
                                  />
                                )}
                              </Svg>
                            </TouchableOpacity>
                          );
                        }
                      )}
                  </ScrollView>
                  <TouchableOpacity
                    style={{ alignItems: "flex-end" }}
                    onPress={() => this.rightArrow()}
                  >
                    <Image
                      source={rightArrow}
                      style={{ width: 30, height: 30 }}
                    />
                  </TouchableOpacity>
                </View>
              )}

              <View style={styles.selectorContent}>
                {this.props.colors &&
                  Object.entries(this.props.colors).map(
                    (hc: any, index: number) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={() => this.props.onColorPress(hc[0])}
                          style={[styles.colors, { backgroundColor: hc[1] }]}
                        />
                      );
                    }
                  )}
              </View>
            </View>
          </View>
        )}
      </View>
    );
    // Customizable Area End
    // Merge Engine - render - End
  };

  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  selectorWrapper: {
    borderWidth: 0.5,
    borderColor: "#7c7f91",
    borderRadius: 5,
    marginTop: 15,
    // paddingBottom: 10,
  },
  selectorTitle: {
    fontSize: 16,
    margin: 10,
    color: "#7c7f91",
    flex: 1,
  },
  selectorInnerWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  selectorContent: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingLeft: 5,
    marginBottom: 5,
  },
  hairSelectorContent: {
    flex: 1,
    flexDirection: "column",
    flexWrap: "wrap",
  },
  colors: {
    height: 30,
    width: 30,
    marginLeft: 8,
    marginBottom: 8,
    borderRadius: 5,
  },
  titleWrapper: {
    flex: 1,
    flexDirection: "row",
  },
  collapseIconActive: {
    width: 30,
    height: 30,
    marginTop: 5,
    marginRight: 5,
    transform: [{ rotate: "-90deg" }],
  },
  collapseIcon: {
    width: 30,
    height: 30,
    marginTop: 5,
    marginRight: 5,
    transform: [{ rotate: "90deg" }],
  },
});
// Customizable Area End
