// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  Switch
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { data, webStyle } from "./IsoData"
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles';

export const IosComponent = ({ classes, ...props }: any) => {
  return (
    <Switch
      data-test-id="my-switch-id"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
}

export const IOSSwitch = withStyles(data)(IosComponent);
// Customizable Area End

import SecuritySettingseController, {
  Props,
} from "./SecuritySettingsController";

export default class SecuritySettings extends SecuritySettingseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPickerElement({ title, key }: any) {
    return (
      <Box sx={webStyle.pickerWrapper}>
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography  className={`${this.props.context.isDark ? "color-white" :""}`} variant='body2' style={webStyle.soundsettingTitle}>{title}</Typography>
          <Typography  className={`${this.props.context.isDark ? "color-white" :""}`} variant='body2' data-test-id="change-pin" onClick={() => { this.setPass("has_login_pin", false) }} style={webStyle.soundsettingSubTitle}>Change pin</Typography>
        </Box>
        <Box className="iso_switch" sx={{ width: 80 }}>
          {/* @ts-ignore */}
          <IOSSwitch data-test-id={`switch-btn-${key}`} checked={this.state.appearenceSettings[key]} onChange={() => this.handleSoundSettings(key)} name="checkedB" />
        </Box>
      </Box>
    )
  }
  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <>
        <div className={`${this.props.context.isDark ? "color-white" :"snackbar-bg"}`}>
				<Snackbar
			open={this.state.is_pin_change}
			autoHideDuration={2000}
					onClose={this.handleClosePinChange}
			message={this.state.message}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
			</div>
      <Container maxWidth={"sm"}>
        <Box className={`${this.props.context.isDark ? "background-none" :""}`} sx={webStyle.mainWrapper}>
          <Typography className={`${this.props.context.isDark ? "color-white" :""}`}  variant="h4" style={webStyle.settingTitle}>Security settings</Typography>
          <p  className={`${this.props.context.isDark ? "color-white" :""}`} style={webStyle.settingDescription}>
            Include additional security like turning on PIN and checking your list of connected devices to keep your account safe.
          </p>
          <Box style={{ marginTop: "15px" }}>
            {!this.state.appearenceSettings.has_login_pin ? 
            <div className="profile-sec">
              <div className="input-field-username input-field">
                <label className={`${this.props.context.isDark ? "color-white" :""}`} >Login PIN </label>
                <input value={this.state.new_pin} data-test-id="on-change-pin" type="number" name="user_name" placeholder="Enter pin" onChange={(e) => { 
                  let num = e?.target?.value.slice(0,8);
                  this.updateNewPin(num)}} />
                  {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.new_pin != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.new_pin != null)]?.new_pin}</span>}
              </div>
              <div className="input-field-username input-field">
                <label className={`${this.props.context.isDark ? "color-white" :""}`} >Confirm Login Pin</label>
                <input value={this.state.confirm_pin} data-test-id="on-change-confirm" type="number" name="user_name" placeholder="Enter pin" onChange={(e) => { 
                   let num = e?.target?.value.slice(0,8);
                  this.updateConfirmPin(num) }} />
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.confirm_pin != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.confirm_pin != null)]?.confirm_pin}</span>}
              </div>
              <div className="button-group">
                <button data-test-id="reset-cancel-data" onClick={() => { this.setPass("has_login_pin", true) }} className="reset">Cancel</button>
                <button data-test-id="save-profile-data" onClick={this.handleChangePasswordSettings} className="save">Change</button>
              </div>
              </div> : this.renderPickerElement({
                title: "Pin for accessing the app",
                key: 'login_with_pin'
              })}
          </Box>
        </Box>
        </Container>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area End

// Customizable Area Start

// Customizable Area End
