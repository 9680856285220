// Customizable Area Start
// @ts-nocheck
import React,{Component} from 'react';


// Customizable Area End

// Customizable Area Start
const ThemeContext = React.createContext();
// Customizable Area End

// Customizable Area Start
export class ThemeProvider extends Component {
    state = {
        isDark : false
    }

    updateDarkMode = (value) => {
        this.setState({isDark:value})
    }

    render(){
        const {isDark} = this.state;
        const {updateDarkMode} = this;
        const values = {
                isDark,
                updateDarkMode
            };
        return (
            <ThemeContext.Provider value={values}>
                {this.props.children}
            </ThemeContext.Provider>
        );
    }
}


export default ThemeContext;

// Customizable Area End
