/** @format */

import React from "react";
// Customizable Area Start
import { Button, Grid, Typography } from "@material-ui/core";
import Navbar from "./Navbar.web";
import DonutChart from "./DonutChart.web";
import DashboardContent from "./DashboardContent.web";
import GameSection from "./GameSection.web";
import "./Dashboard.scss";
import ChatBoxCard from "./ChatBoxCard.web";
import DashboardControllerWeb, { Props } from "./DashboardControllerWeb";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import { notificationSound } from "./assets";
import ThemeContext from "../../../components/src/ThemeContext.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
class Dashboard extends DashboardControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <DashboardSidebar context={this.context} history={this.props.navigation} selected="Dashboard" />
        <Grid container className={`main-container app-page-dashboard ${this.context.isDark ? "dark-color" :""}`}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={`container-items ${this.context.isDark ? "dark-color" :""}`}
          >
            <Navbar context={this.context} title="Welcome" navigation={this.props.navigation} />
            <Grid container>
              <Grid item xs={12} sm={6} md={7} lg={7}>
                <div className="dashboard-container">
                  <DashboardContent context={this.context}/>
                  <GameSection context={this.context}/>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={5} lg={5}>
                <div className="chat-graph-conatiner">
                  <div className={`chat-box ${this.context.isDark ? "dark-side-color" :""}`}>
                    <div className="nav-header">
                      <Typography className={`activity ${this.context.isDark ? "color-white" :""}`} variant="h5">
                        Activity
                      </Typography>
                      <Button className={`${this.context.isDark ? "color-white" :""}`}>View all</Button>
                    </div>
                    <div className="scroller">
                      {this.state.userData.slice(0, 6).map((item: any) => (
                        <div className={`chat-card-main" ${this.context.isDark ? "color-white" :""}`} key={item.id}>
                          <ChatBoxCard item={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <DonutChart context={this.context}/>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <audio ref={this.audioRef} controls={false}>
            <source src={notificationSound} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </>
    );
  }
}
export default Dashboard;

Dashboard.contextType = ThemeContext;

// Customizable Area End
