/** @format */

import React from "react";
// Customizable Area Start
import EmailAccountRegistrationController, {
  Props
} from "./EmailRegistrationController";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import './EmailAccountRegistration.scss'
import Sidebar from "../../../components/src/Sidebar.web";
import TextField from '@material-ui/core/TextField';
import ThemeContext from '../../../components/src/ThemeContext.web';
import Snackbar from '@material-ui/core/Snackbar'
// Customizable Area End

// Customizable Area Start
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// Customizable Area End

// Customizable Area Start
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const personalData = (<li className="app-register-form-field-group-item">
      <Grid container spacing={2}>
        <Grid item md={12} lg={6}>
          <div className="app-register-form-field">
            <label className={`app-register-form-field-label ${this.context.isDark ? "color-white" : ''}`}>Country of residence</label>
            <FormControl className="w-100" >
              <Select
                displayEmpty
                className={`app-custom-select ${this.context.isDark ? 'textfield-color' : ''}`}
                value={this.state.countryCodeSelected}
                onChange={this.handleCountryCode}
                // IconComponent = {down_icon}
                input={<OutlinedInput />}
                renderValue={(selected: any) => {
                  if (selected.length === 0) {
                    return <span className="app-custom-select-placeholder">Select Country</span>;
                  }

                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  Select Country
                </MenuItem>
                {this.state.countryCodeData.sort((a: any, b: any) => {
                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;
                    return 0;
                  }).map((option: any ,index:any) => (
                  <MenuItem key={index} value={`+${option.phone} ${option.label} (${option.code})`}
                  >
                    +{option.phone} {option.label} ({option.code})
                  </MenuItem>
                ))}
              </Select>
              {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.countryCodeSelected != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.countryCodeSelected != null)]?.countryCodeSelected}</span>}
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </li>);
    const dobData = (<li className="app-register-form-field-group-item">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="app-register-form-field">
            <label className={`app-register-form-field-label ${this.context.isDark ? "color-white" : ''}`}>Personal Information</label>

            <Grid container spacing={2}>
              <Grid item
                xs={2}
              >
                <Select
                  displayEmpty
                  className={`app-custom-select personal-select ${this.context.isDark ? 'textfield-color' : ''}`}
                  value={this.state.title}
                  onChange={this.handleUserTitle}
                  input={<OutlinedInput />}
                  renderValue={(selected: any) => {
                    return selected
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="Mr">
                    Mr
                  </MenuItem>
                  <MenuItem value="Ms">
                    Ms
                  </MenuItem>
                </Select>
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.title != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.title != null)]?.title}</span>}
              </Grid>
              <Grid item
                xs={4}

              >
                <input type={'text'} onChange={this.handleUserFirstName} placeholder="First Name" className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.first_name != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.first_name != null)]?.first_name}</span>}
              </Grid>
              <Grid item xs={6}>
                <input type={'text'} placeholder="Last Name" onChange={this.handleUserLastName} className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.last_name != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.last_name != null)]?.last_name}</span>}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </li>);
    const contactData = (<li className="app-register-form-field-group-item">
      <Grid container>
        <Grid item xs={12}>
          <div className="app-register-form-field">
            <label className={`app-register-form-field-label ${this.context.isDark ? "color-white" : ''}`}>Contact information</label>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <input type={'text'} onChange={this.handleUserEmail} placeholder="Email Address" className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.account != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.account != null)]?.account}</span>}
              </Grid>
              <Grid item xs={12} md={6}>
                <input type={'number'} onChange={this.handleUserMobileNum} placeholder="Contact number" className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.full_phone_number != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.full_phone_number != null)]?.full_phone_number}</span>}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </li>);
    const loginData = (<li className="app-register-form-field-group-item">
      <Grid container>
        <Grid item xs={12}>
          <div className="app-register-form-field">
            <label className={`app-register-form-field-label ${this.context.isDark ? "color-white" : ''}`}>Create Login</label>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <input type={'text'} onChange={this.handleUserName} placeholder="Username" className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.username != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.username != null)]?.username}</span>}

              </Grid>
              <Grid item xs={12} md={6}>
                <input type={'password'} onChange={this.handleUserPassword} placeholder="Password" className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.pass_code != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.pass_code != null)]?.pass_code}</span>}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </li>);
    const securityData = (
      <li className="app-register-form-field-group-item">
        <Grid container>
          <Grid item xs={12}>
            <div className="app-register-form-field">
              <label className={`app-register-form-field-label ${this.context.isDark ? "color-white" : ''}`}>Security pin</label>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <input value={this.state.security_number} type={'number'} onChange={this.handleSecurityCode} placeholder="Four-digit security number" className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                  {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.security_number != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.security_number != null)]?.security_number}</span>}
                </Grid>
                <Grid item xs={12} md={6}>
                  <input value={this.state.confirm_security_number} type={'number'} onChange={this.handleConfirmSecurityCode} placeholder="Confirm four-digit security number" className={`app-register-form-field-input ${this.context.isDark ? "textfield-color" : ''}`} />
                  {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.confirm_security_number != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.confirm_security_number != null)]?.confirm_security_number}</span>}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </li>
    );
    const checkboxData = (
      <li className="app-register-form-field-group-item">
        <Grid container>
          <Grid item xs={12}>
            <div className={`app-register-form-field-terms ${this.context.isDark ? "color-white" : ''}`}>
              <FormControlLabel
                label="I am at least 18 years of age and I have read, accept and agree to the Terms and Conditions, Rules, Privacy policy, Cookies Policy, and policies relating to age and identity verification."
                control={
                  <Checkbox
                  />
                }
                onChange={this.handleUserChecked}
              />
            </div>
          </Grid>
        </Grid>
      </li>
    );
    return (
      <>
        <Sidebar from="register" history={this.props.navigation} context={this.context} />
        <div className={`${this.context.isDark ? "dark-color" : "snackbar-error"}`} >
				<Snackbar
			open={this.state.showsnack}
			autoHideDuration={2000}
					onClose={this.handleClose}
			message='Please check terms and conditon'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
			</div>
        <div className={`app-page-section register-page ${this.context.isDark ? "dark-color" : ""}`}>
          <div className="app-page-wrapper app-register-wrapper">
            <div className="app-register-form">
              <h1 className={`app-register-form-title ${this.context.isDark ? "color-white" : ""}`}>Open Account</h1>
              <form className="form-sec">
                <ul className="app-register-form-field-group">
                  {personalData}
                  {dobData}
                  <li className="app-register-form-field-group-item">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <div className="app-custom-datepicker">
                          <div className="app-register-form-field">
                            <label className={`app-register-form-field-label ${this.context.isDark ? "color-white" : ''}`}>Date of birth</label>
                            <TextField
                              className={`textField ${this.context.isDark ? 'date-color' : ''}`}
                              id="date-local"
                              type="date"
                              
                              placeholder="DOB"
                              onChange={(e: any) => {
                                this.handleUserDateOfBirth(e)
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                max: `${this.state.maxDate}`
                              }}
                              inputRef={this.dateInputRef}
                            />
                            {this.state.errorsList?.length > 0 && this.state.errorsList.findIndex((item: any) => item?.date != null) >= 0 && <span className="error-text">{this.state.errorsList[this.state.errorsList.findIndex((item: any) => item?.date != null)]?.date}</span>}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </li>
                  {contactData}
                  {loginData}
                  {securityData}
                  {checkboxData}
                  <li className={`app-register-form-field-group-item `}>
                    <Grid container>
                      <Grid item xs={12}>
                        <div className="app-register-form-field-button">
                          <Button className={`app-register-form-button ${this.context.isDark ? "btn-color color-white" : ''}`} variant="contained" onClick={this.btnSignUpProps.onPress}>Join Esfera</Button>
                        </div>
                      </Grid>
                    </Grid>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}


EmailAccountRegistration.contextType = ThemeContext;

// Customizable Area End
