// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  test?: any;
  context:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  listData: any;
  currentState: any;
  is_active: boolean;
  open: boolean;
  userData: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}
// Customizable Area Start
export const SettingItems = [
  {
    id: 1,
    title: "Notifications",
    path: "notifications",
  },
  {
    id: 2,
    title: "Sounds",
    path: "sound",
  },
  {
    id: 3,
    title: "Preferred currency",
    path: "preferred_currency",
  },
  {
    id: 4,
    title: "Security settings",
    path: "security_settings",
  },
  {
    id: 5,
    title: "Public profile",
    path: "profile",
  },
  {
    id: 6,
    title: "Auto Post",
    path: "auto_post",
  },
  {
    id: 7,
    title: "Appearance",
    path: "appearance",
  },
  {
    id: 8,
    title: "About",
    path: "about",
  },
  {
    id: 9,
    title: "FAQs",
    path: "faqs",
  },
  {
    id: 10,
    title: "Contact Us",
    path: "contact_us",
  },
];
// Customizable Area End
export default class SettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      listData: [],
      currentState: "",
      is_active: false,
      open: false,
      userData: null
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  handleButtonClick = (id: string) => {
    this.setState({ currentState: id, is_active: true });
  };

  getClassFunc = (item:any) => {
    const data = item.id === 1 && !this.state.currentState.length
    ? "active"
        : "items"
      
        return data
  }
  getClassName = (item: any) => {
    const className =  this.state.currentState === item.path && this.state.is_active
    ? "active"
    : this.getClassFunc(item)
    return className;
  };

  getDataFromPublicProfile = (data: any) => {
    this.setState({userData: data})
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
// Customizable Area End
