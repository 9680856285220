// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

// Customizable Area Start

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  
  
}

interface S {
  
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  loading: boolean;
  errorMessage: any;
  securityPin: any;
  confirm_password: any;
  showPin: boolean,
  message_final:any,
  counter:any,
  startTimer:boolean
  login_with_pin: boolean,
  showsnack:boolean
}

interface SS {
  
  id: any;
  
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  apiEmailLoginCallId: string = "";
  apiSecurityCallId: string = "";
  apiResendOtpId: string = "";
  apiVerifySecurtyPinId: string = "";
  apiVerifySecurtyCodeId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  detailProfileCallId:string = "";
  

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.log(this.context);
    
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      loading: false,
      errorMessage: "",
      securityPin: "",
      confirm_password: "",
      showPin: false,
      message_final:"",
      login_with_pin:false,
      counter:60,
      startTimer:false,
      showsnack:false
    };

    this.labelTitle = configJSON.labelTitle;
    

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  checkSecurityCode() {
    if(this.state.securityPin != localStorage.getItem("securityCode")?.toString()){
      this.setState({
        errorMessage:"Please recheck the security pin"
      })
      return
    }
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const attrs = {
      "pin": this.state.securityPin
    };

    const data = {

      "attributes": attrs,
      "type": "email_account"
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiVerifySecurtyCodeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.securityCodeAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.securityPinAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  resendOtpApi() {

    const header = {
      "Content-Type": configJSON.loginApiContentType,
      "token": localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResendOtpId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOtpAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.resendOtpAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  getToken = () => {
    const token = localStorage.getItem("accessToken")
    return token
}

  changeLoginWithPin() {
    this.setState({
      login_with_pin: !this.state.login_with_pin,
    });
  }


 coundown(){
   setInterval(() => {
  if (this.state.counter == 0) {
    this.setState({counter:60,startTimer:false});
    return;
  }
  this.setState({counter:this.state.counter - 1});

}, 1000);
}

  changePasswordApi() {
    let decimal=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
    if (this.state.securityPin != localStorage.getItem("securityCode")) {
      this.setState({
        errorMessage: "Please recheck the security pin"
      })
      return;
    }
    if (this.state.password != this.state.confirm_password) {
      this.setState({
        errorMessage: "new password and confirm password need to be same"
      })
      return;
    }

    if (!this.state.password.match(decimal)) {
      this.setState({
        errorMessage: "Password should contain 1 special charecter,1 uppercase,1 lowercase and 1 digit"
      })
      return;
    }


    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      "new_password": this.state.password,
      "token": localStorage.getItem("accessToken"),
      "otp_code": this.state.securityPin
    };

    const data = {

      ...attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSecurityCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.changePasswordAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }


  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }

  
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  handleShowPin = (showPin: boolean) => {
    this.setState({showPin});
}

  handleLogout = () => {
    localStorage.clear();
    this.props.navigation.navigate("Home")
  }
  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setSecurityCode = (text: string) => {
    this.setState({
      securityPin: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setConfirmPassword = (text: string) => {
    this.setState({
      confirm_password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  

  onVerifyOtp = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.apiVerifySecurtyCodeId) {
      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        localStorage.setItem("accessToken", responseJson.meta.token)
        localStorage.setItem("entered", "true")
        this.props.navigation.navigate("LockScreen")
        
      

        this.sendLoginSuccessMessage();
      
      } else if (responseJson && responseJson?.errors) {
        this.setState({ errorMessage: responseJson.errors })
      } else {
        this.setState({ errorMessage: "Something went wrong" })
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
        this.sendLoginFailMessage();
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  responseJsonData = (responseJson:any) => {
    if (responseJson && responseJson?.errors) {
      if (responseJson.errors[0]?.otp) {
        this.setState({ errorMessage: responseJson.errors[0]?.otp })
      }
      if (responseJson.errors[0]?.token) {
        this.setState({ errorMessage: responseJson.errors[0]?.token })
      }
      if (responseJson.errors[0]?.password) {
        this.setState({ errorMessage: responseJson.errors[0]?.password })
      }

    }
}
  onVerifySecurity = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.apiVerifySecurtyPinId) {
      console.log(responseJson)
      if(responseJson?.message == "Invalid pin!"){
        this.setState({message_final:responseJson?.message})
        return;
      }
      this.responseJsonData(responseJson)
      this.parseApiCatchErrorResponse(errorReponse);
      this.props.navigation.navigate("Dashboard");
    }
   this.onVerifyOtp(apiRequestCallId,responseJson,errorReponse)
  }

  onResendOtpResponse = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.apiResendOtpId) {
      console.log(responseJson)
      this.responseJsonData(responseJson)
      this.setState({showsnack:true})
      this.setState({startTimer:true})
      this.coundown();
      localStorage.setItem("accessToken", responseJson.meta?.token)
      localStorage.setItem("securityCode", responseJson.data?.attributes?.pin)
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  onSecurityCodeReceived = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.apiSecurityCallId) {
      console.log(responseJson)
      this.responseJsonData(responseJson)
      localStorage.clear();
      this.props.navigation.navigate("EmailAccountLoginBlock")
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }
  handleClose=()=> {
    this.setState({showsnack: false});
    }
  onReceieveData = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
    if (apiRequestCallId === this.apiEmailLoginCallId) {

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        localStorage.setItem("accessToken", responseJson.meta.token)
        localStorage.setItem("securityCode", responseJson.data?.attributes?.pin)
        this.props.navigation.navigate("SecurityCode")
        this.sendLoginSuccessMessage();
      } else if (responseJson && responseJson?.errors) {
        this.setState({ errorMessage: responseJson.errors })
      }else if (responseJson && responseJson?.message) {
        this.setState({ errorMessage: responseJson.message })
      } else {
        this.setState({ errorMessage: "Something went wrong" })
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
        this.sendLoginFailMessage();
      }

      this.parseApiCatchErrorResponse(errorReponse);
    }
    this.onSecurityCodeReceived(apiRequestCallId,responseJson,errorReponse)
    this.onResendOtpResponse(apiRequestCallId,responseJson,errorReponse)
    this.onVerifySecurity(apiRequestCallId,responseJson,errorReponse)
  }
countryCodeValid(countryCode:any,userName:any,password:any){
  if (!countryCode && userName && password) {
    this.setState({
      email: userName,
      password: password,
      checkedRememberMe: true,
    });

    //@ts-ignore
    this.txtInputEmailProps.value = userName;

    //@ts-ignore
    this.txtInputPasswordProps.value = password;

    this.CustomCheckBoxProps.isChecked = true;
  }
}

apiCallData(apiRequestCallId:any,responseJson:any,errorReponse:any){
  if (apiRequestCallId != null) {
    if (
      apiRequestCallId === this.validationApiCallId &&
      responseJson !== undefined
    ) {
      let arrayholder = responseJson.data;
      if (arrayholder && arrayholder.length !== 0) {
        let regexData = arrayholder[0];

        if (regexData && regexData.email_validation_regexp) {
          console.log("this is regx", regexData.email_validation_regexp);
        }
      }
    }
    this.onReceieveData(apiRequestCallId,responseJson,errorReponse)
  }
}
  async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));
      const password = message.getData(getName(MessageEnum.LoginPassword));
      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );
      this.countryCodeValid(countryCode,userName,password);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.apiCallData(apiRequestCallId,responseJson,errorReponse)
    }
    
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const message: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    message.addData(getName(MessageEnum.LoginUserName), this.state.email);
    message.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    message.addData(getName(MessageEnum.LoginPassword), this.state.password);
    message.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(message);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const savelogmsg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      savelogmsg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      savelogmsg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(savelogmsg);
    }
  }

  openInfoPage() {
    const usermsg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    usermsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(usermsg);
  }

  goToForgotPassword() {
    const passwordmsg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    passwordmsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    passwordmsg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(passwordmsg);
  }

  goToSocialLogin() {
    const passwordmsg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    passwordmsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(passwordmsg);
  }

  doEmailLogIn() {
    if (
      this.state.email === null ||
      this.state.email.length === 0 
    ) {
      this.setState({ errorMessage: configJSON.errorEmailNotValid });
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = this.state.login_with_pin ?  {
      email: this.state.email,
      login_pin:parseInt(this.state.password)
    } : {
      email: this.state.email,
      password:this.state.password
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

}


// Customizable Area End
