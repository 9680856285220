Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.changeSoundSettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/update_sound_setting";
exports.getSoundSettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/fetch_sound_setting";
exports.getCurrencySettingApiEndpoint = "bx_block_fedex_integration/currency_list";
exports.changeAppearenceSettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/change_mode";
exports.changeCurrancySettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/change_currency";
exports.getAppearenceSettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/check_additional_security";
exports.getSecuritySettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/check_additional_security";
exports.changeSecuritySettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/check_additional_security";
exports.changepasswordSettingApiEndpoint = "bx_block_privacy_settings/privacy_settings/change_login_pin"
exports.contactUsApiEndPoints = "bx_block_contact_us/contacts"
exports.putApiMethodType = "PUT"
exports.postApiMethodType = "POST"
exports.patchApiMethodType = "PATCH"
exports.getMethodForSoundSetting = "GET"

exports.btnExampleTitle = "CLICK ME";
exports.faqApiMethodType = "GET";
exports.faqApiEndPoint = "bx_block_interactive_faqs/interactive_faqs";
exports.updateApiMethodType = "PUT";
exports.updateProfileApiEndPoint = "bx_block_settings/settings/update_profile";
exports.profileDetailApiMethodType = "GET";
exports.profileDetailApiEndPoint = "bx_block_settings/settings/account_detail"
// Customizable Area End