// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  test?: any;
  context?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  contactUs: {
    first_name: string,
    last_name: string,
    email: string,
    phone_number: string,
    description: string
  }
  errorMessage: any,
  is_open:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class SettinContactus extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiChangeSoundSettings: string = "";
  faqDetailCallId: string = "";
  contactUsApiId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      contactUs: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        description: "",
       
      },
      errorMessage: [],
      is_open:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  getToken = () => {
    const token = localStorage.getItem("accessToken");
    return token;
  };
  handleCloseContactus=()=> {
  this.setState({is_open:false});
  }
  handleContactUsFormInputChange = (e: any) => {
     const contactUs = {...this.state.contactUs};
     const name = e.target.name;
     const value = e.target.value
     //@ts-ignore
     contactUs[name] = value;
     this.setState({contactUs: contactUs});
  }

   ValidateEmailAddress(emailString:any) {
    // check for @ sign
    let atSymbol = emailString.indexOf("@");
    if(atSymbol < 1) return false;
    
    let dot = emailString.indexOf(".");
    if(dot <= atSymbol + 2) return false;
    
    // check that the dot is not at the end
    if (dot === emailString.length - 1) return false;
    
    return true;
}
handleValidationFunc =(contactUs:any) => {

let errorData = [];

  if(!this.ValidateEmailAddress(contactUs?.email)){
    errorData.push({email:"please enter valid email"});
  }
  if(contactUs?.phone_number.length < 10 ){
    errorData.push({phone:"please enter valid phone number"});
  }

  if(!contactUs?.first_name){
    errorData.push({first_name:"please enter first name"});
  }
  if(contactUs?.last_name == "" ){
    errorData.push({last_name:"please enter last name"});
  }
  if(contactUs?.description == "" ){
    errorData.push({description:"please enter description"});
  }

  this.setState({errorMessage:errorData});

  if(errorData.length > 0){
    return false;
  }

  return true;
}

  handleSubmitContactUs = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };
  
    let bool = this.handleValidationFunc(this.state.contactUs);

    if(!bool){
      return;
    }

    const data = {
      "attributes": this.state.contactUs,
      "type": "contact"
    };


    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactUsApiEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }



  handleConactUsResponse = (from: string, message: Message) => {

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.contactUsApiId &&
        responseJson !== undefined
      ) {
        
        this.setState({is_open:true,contactUs:{
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          description: "",
         
        }})
      }
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleConactUsResponse(from, message)
    // Customizable Area End
  }
}
// Customizable Area End
