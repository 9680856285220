/** @format */

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");

export interface Props {
	navigation?: any;
	id?: string;
	// Customizable Area Start
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class UserProfileDetailController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start

	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

		this.state = {};

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// Customizable Area End
	}

	// Customizable Area Start

	// Customizable Area End
}
// Customizable Area End
