/** @format */

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
	profileImg1,
	profileImg2,
	profileImg3,
} from "../../dashboard/src/assets";
import { gameImage } from "./assets";
// Customizable Area End

// Customizable Area Start
const data = [
	{
		id: 1,
		image: gameImage,
		description:
			"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam rerum veritatis illum quasi libero ab tempore nemo itaque",
		cardItem: {
			profileImg: profileImg1,
			name: "Isobel Rhodes",
			status: "Online",
		},
	},
	{
		id: 2,
		image: gameImage,
		description:
			"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam rerum veritatis illum quasi libero ab tempore nemo itaque",
		cardItem: {
			profileImg: profileImg2,
			name: "Isobel Rhodes",
			status: "Online",
		},
	},
	{
		id: 3,
		image: gameImage,
		description:
			"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nam rerum veritatis illum quasi libero ab tempore nemo itaque",
		cardItem: {
			profileImg: profileImg3,
			name: "Isobel Rhodes",
			status: "Online",
		},
	},
];
export const configJSON = require("./config");

export interface Props {
	navigation?: any;
	id?: string;
	// Customizable Area Start
	// Customizable Area End
}

export interface playerData {
	id: number;
	image: string;
	description: string;
	cardItem: {
		profileImg: string;
		name: string;
		status: string;
	};
}
interface S {
	// Customizable Area Start
	playerdata: playerData[];
	isFavourite: boolean;
	isActive: number | string;
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}
// Customizable Area End

// Customizable Area Start
export default class PlayerActivityController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start

	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

		this.state = {
			playerdata: data,
			isFavourite: false,
			isActive: "",
		};

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// Customizable Area End
	}

	// Customizable Area Start
	clickforFavourite = (id: number) => {
		this.setState({ isFavourite: !this.state.isFavourite, isActive: id });
	};
	// Customizable Area End
}
// Customizable Area End
