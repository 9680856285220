export const countriesCode = [
    {id:1, code: 'AD', label: 'Andorra', phone: '376' },
    { id:2,code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { id:3 ,code: 'AF', label: 'Afghanistan', phone: '93' },
    { id:4 ,code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { id: 5,code: 'AI', label: 'Anguilla', phone: '1-264' },
    { id:6 ,code: 'AL', label: 'Albania', phone: '355' },
    { id:7 ,code: 'AM', label: 'Armenia', phone: '374' },
    { id:8 ,code: 'AO', label: 'Angola', phone: '244' },
    { id: 9,code: 'AQ', label: 'Antarctica', phone: '672' },
    { id: 10,code: 'AR', label: 'Argentina', phone: '54' },
    { id: 11,code: 'AS', label: 'American Samoa', phone: '1-684' },
    { id: 13,code: 'AT', label: 'Austria', phone: '43' },
    { id:13 ,code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { id: 14,code: 'AW', label: 'Aruba', phone: '297' },
    { id: 15,code: 'AX', label: 'Alland Islands', phone: '358' },
    { id: 16,code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { id: 17,code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { id: 18,code: 'BB', label: 'Barbados', phone: '1-246' },
    { id:19 ,code: 'BD', label: 'Bangladesh', phone: '880' },
    { id:20 ,code: 'BE', label: 'Belgium', phone: '32' },
    { id: 21,code: 'BF', label: 'Burkina Faso', phone: '226' },
    { id: 22,code: 'BG', label: 'Bulgaria', phone: '359' },
    { id:23,code: 'BH', label: 'Bahrain', phone: '973' },
    { id:24,code: 'BI', label: 'Burundi', phone: '257' },
    { id:25,code: 'BJ', label: 'Benin', phone: '229' },
    { id:26,code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { id:27,code: 'BM', label: 'Bermuda', phone: '1-441' },
    { id:28,code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { id:29,code: 'BO', label: 'Bolivia', phone: '591' },
    { id:30,code: 'BR', label: 'Brazil', phone: '55' },
    { id:31,code: 'BS', label: 'Bahamas', phone: '1-242' },
    { id:32,code: 'BT', label: 'Bhutan', phone: '975' },
    { id:33,code: 'BV', label: 'Bouvet Island', phone: '47' },
    { id:34,code: 'BW', label: 'Botswana', phone: '267' },
    { id:35,code: 'BY', label: 'Belarus', phone: '375' },
    { id:36,code: 'BZ', label: 'Belize', phone: '501' },
    { id:37,code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { id:38,code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { id:39,code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { id:40,code: 'CF', label: 'Central African Republic', phone: '236' },
    { id:41,code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { id:42,code: 'CH', label: 'Switzerland', phone: '41' },
    { id:43,code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { id:44,code: 'CK', label: 'Cook Islands', phone: '682' },
    { id:45,code: 'CL', label: 'Chile', phone: '56' },
    { id:46,code: 'CM', label: 'Cameroon', phone: '237' },
    { id:47,code: 'CN', label: 'China', phone: '86' },
    { id:48,code: 'CO', label: 'Colombia', phone: '57' },
    { id:49,code: 'CR', label: 'Costa Rica', phone: '506' },
    { id:50,code: 'CU', label: 'Cuba', phone: '53' },
    { id:51,code: 'CV', label: 'Cape Verde', phone: '238' },
    { id:52,code: 'CW', label: 'Curacao', phone: '599' },
    { id:53,code: 'CX', label: 'Christmas Island', phone: '61' },
    { id:54,code: 'CY', label: 'Cyprus', phone: '357' },
    { id:55,code: 'CZ', label: 'Czech Republic', phone: '420' },
    { id:56,code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { id:57,code: 'DJ', label: 'Djibouti', phone: '253' },
    { id:58,code: 'DK', label: 'Denmark', phone: '45' },
    { id:59,code: 'DM', label: 'Dominica', phone: '1-767' },
    { id:60,code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { id:61,code: 'DZ', label: 'Algeria', phone: '213' },
    { id:62,code: 'EC', label: 'Ecuador', phone: '593' },
    { id:63,code: 'EE', label: 'Estonia', phone: '372' },
    { id:64,code: 'EG', label: 'Egypt', phone: '20' },
    { id:65,code: 'EH', label: 'Western Sahara', phone: '212' },
    { id:66,code: 'ER', label: 'Eritrea', phone: '291' },
    { id:67,code: 'ES', label: 'Spain', phone: '34' },
    { id:68,code: 'ET', label: 'Ethiopia', phone: '251' },
    { id:69,code: 'FI', label: 'Finland', phone: '358' },
    { id:70,code: 'FJ', label: 'Fiji', phone: '679' },
    { id:71,code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { id:72,code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { id:73,code: 'FO', label: 'Faroe Islands', phone: '298' },
    { id:74,code: 'FR', label: 'France', phone: '33', suggested: true },
    { id:75,code: 'GA', label: 'Gabon', phone: '241' },
    { id:76,code: 'GB', label: 'United Kingdom', phone: '44' },
    { id:77,code: 'GD', label: 'Grenada', phone: '1-473' },
    { id:78,code: 'GE', label: 'Georgia', phone: '995' },
    { id:79,code: 'GF', label: 'French Guiana', phone: '594' },
    { id:80,code: 'GG', label: 'Guernsey', phone: '44' },
    { id:81,code: 'GH', label: 'Ghana', phone: '233' },
    { id:82,code: 'GI', label: 'Gibraltar', phone: '350' },
    { id:83,code: 'GL', label: 'Greenland', phone: '299' },
    { id:84,code: 'GM', label: 'Gambia', phone: '220' },
    { id:85,code: 'GN', label: 'Guinea', phone: '224' },
    { id:86,code: 'GP', label: 'Guadeloupe', phone: '590' },
    { id:87,code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { id:88,code: 'GR', label: 'Greece', phone: '30' },
    { id:89,code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { id:90,code: 'GT', label: 'Guatemala', phone: '502' },
    { id:91,code: 'GU', label: 'Guam', phone: '1-671' },
    { id:92,code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { id:93,code: 'GY', label: 'Guyana', phone: '592' },
    { id:94,code: 'HK', label: 'Hong Kong', phone: '852' },
    { id:95,code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { id:96,code: 'HN', label: 'Honduras', phone: '504' },
    { id:97,code: 'HR', label: 'Croatia', phone: '385' },
    { id:98,code: 'HT', label: 'Haiti', phone: '509' },
    { id:99,code: 'HU', label: 'Hungary', phone: '36' },
    { id:100,code: 'ID', label: 'Indonesia', phone: '62' },
    { id:101,code: 'IE', label: 'Ireland', phone: '353' },
    { id:102,code: 'IL', label: 'Israel', phone: '972' },
    { id:103,code: 'IM', label: 'Isle of Man', phone: '44' },
    { id:104,code: 'IN', label: 'India', phone: '91' },
    { id:105,code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { id:106,code: 'IQ', label: 'Iraq', phone: '964' },
    { id:107,code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { id:108,code: 'IS', label: 'Iceland', phone: '354' },
    { id:109,code: 'IT', label: 'Italy', phone: '39' },
    { id:110,code: 'JE', label: 'Jersey', phone: '44' },
    { id:111,code: 'JM', label: 'Jamaica', phone: '1-876' },
    { id:112,code: 'JO', label: 'Jordan', phone: '962' },
    { id:113,code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { id:114,code: 'KE', label: 'Kenya', phone: '254' },
    { id:115,code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { id:116,code: 'KH', label: 'Cambodia', phone: '855' },
    { id:117,code: 'KI', label: 'Kiribati', phone: '686' },
    { id:118,code: 'KM', label: 'Comoros', phone: '269' },
    { id:119,code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    { id:120,code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
    { id:121,code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { id:122,code: 'KW', label: 'Kuwait', phone: '965' },
    { id:123,code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { id:124,code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { id:125,code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { id:126,code: 'LB', label: 'Lebanon', phone: '961' },
    { id:127,code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { id:128,code: 'LI', label: 'Liechtenstein', phone: '423' },
    { id:129,code: 'LK', label: 'Sri Lanka', phone: '94' },
    { id:130,code: 'LR', label: 'Liberia', phone: '231' },
    { id:131,code: 'LS', label: 'Lesotho', phone: '266' },
    { id:132,code: 'LT', label: 'Lithuania', phone: '370' },
    { id:133,code: 'LU', label: 'Luxembourg', phone: '352' },
    { id:134,code: 'LV', label: 'Latvia', phone: '371' },
    { id:135,code: 'LY', label: 'Libya', phone: '218' },
    { id:136,code: 'MA', label: 'Morocco', phone: '212' },
    { id:137,code: 'MC', label: 'Monaco', phone: '377' },
    { id:138,code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { id:139,code: 'ME', label: 'Montenegro', phone: '382' },
    { id:140,code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { id:141,code: 'MG', label: 'Madagascar', phone: '261' },
    { id:142,code: 'MH', label: 'Marshall Islands', phone: '692' },
    { id:143,code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { id:144,code: 'ML', label: 'Mali', phone: '223' },
    { id:145,code: 'MM', label: 'Myanmar', phone: '95' },
    { id:146,code: 'MN', label: 'Mongolia', phone: '976' },
    { id:147,code: 'MO', label: 'Macao', phone: '853' },
    { id:148,code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { id:149,code: 'MQ', label: 'Martinique', phone: '596' },
    { id:150,code: 'MR', label: 'Mauritania', phone: '222' },
    { id:151,code: 'MS', label: 'Montserrat', phone: '1-664' },
    { id:152,code: 'MT', label: 'Malta', phone: '356' },
    { id:153,code: 'MU', label: 'Mauritius', phone: '230' },
    { id:154,code: 'MV', label: 'Maldives', phone: '960' },
    { id:155,code: 'MW', label: 'Malawi', phone: '265' },
    { id:156,code: 'MX', label: 'Mexico', phone: '52' },
    { id:157,code: 'MY', label: 'Malaysia', phone: '60' },
    { id:158,code: 'MZ', label: 'Mozambique', phone: '258' },
    { id:159,code: 'NA', label: 'Namibia', phone: '264' },
    { id:160,code: 'NC', label: 'New Caledonia', phone: '687' },
    { id:161,code: 'NE', label: 'Niger', phone: '227' },
    { id:162,code: 'NF', label: 'Norfolk Island', phone: '672' },
    { id:163,code: 'NG', label: 'Nigeria', phone: '234' },
    { id:164,code: 'NI', label: 'Nicaragua', phone: '505' },
    { id:165,code: 'NL', label: 'Netherlands', phone: '31' },
    { id:166,code: 'NO', label: 'Norway', phone: '47' },
    { id:167,code: 'NP', label: 'Nepal', phone: '977' },
    { id:168,code: 'NR', label: 'Nauru', phone: '674' },
    { id:169,code: 'NU', label: 'Niue', phone: '683' },
    { id:170,code: 'NZ', label: 'New Zealand', phone: '64' },
    { id:171,code: 'OM', label: 'Oman', phone: '968' },
    { id:172,code: 'PA', label: 'Panama', phone: '507' },
    { id:173,code: 'PE', label: 'Peru', phone: '51' },
    { id:174,code: 'PF', label: 'French Polynesia', phone: '689' },
    { id:175,code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { id:176,code: 'PH', label: 'Philippines', phone: '63' },
    { id:177,code: 'PK', label: 'Pakistan', phone: '92' },
    { id:178,code: 'PL', label: 'Poland', phone: '48' },
    { id:179,code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { id:180,code: 'PN', label: 'Pitcairn', phone: '870' },
    { id:181,code: 'PR', label: 'Puerto Rico', phone: '1' },
    { id:182,code: 'PS', label: 'Palestine, State of', phone: '970' },
    { id:183,code: 'PT', label: 'Portugal', phone: '351' },
    { id:184,code: 'PW', label: 'Palau', phone: '680' },
    { id:185,code: 'PY', label: 'Paraguay', phone: '595' },
    { id:186,code: 'QA', label: 'Qatar', phone: '974' },
    { id:187,code: 'RE', label: 'Reunion', phone: '262' },
    { id:188,code: 'RO', label: 'Romania', phone: '40' },
    { id:189,code: 'RS', label: 'Serbia', phone: '381' },
    { id:190,code: 'RU', label: 'Russian Federation', phone: '7' },
    { id:191,code: 'RW', label: 'Rwanda', phone: '250' },
    { id:192,code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { id:193,code: 'SB', label: 'Solomon Islands', phone: '677' },
    { id:194,code: 'SC', label: 'Seychelles', phone: '248' },
    { id:195,code: 'SD', label: 'Sudan', phone: '249' },
    { id:196,code: 'SE', label: 'Sweden', phone: '46' },
    { id:197,code: 'SG', label: 'Singapore', phone: '65' },
    { id:198,code: 'SH', label: 'Saint Helena', phone: '290' },
    { id:199,code: 'SI', label: 'Slovenia', phone: '386' },
    { id:200,code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { id:201,code: 'SK', label: 'Slovakia', phone: '421' },
    { id:202,code: 'SL', label: 'Sierra Leone', phone: '232' },
    { id:203,code: 'SM', label: 'San Marino', phone: '378' },
    { id:204,code: 'SN', label: 'Senegal', phone: '221' },
    { id:205,code: 'SO', label: 'Somalia', phone: '252' },
    { id:206,code: 'SR', label: 'Suriname', phone: '597' },
    { id:207,code: 'SS', label: 'South Sudan', phone: '211' },
    { id:208,code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { id:209,code: 'SV', label: 'El Salvador', phone: '503' },
    { id:210,code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { id:211,code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { id:212,code: 'SZ', label: 'Swaziland', phone: '268' },
    { id:213,code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { id:214,code: 'TD', label: 'Chad', phone: '235' },
    { id:215,code: 'TF', label: 'French Southern Territories', phone: '262' },
    { id:216,code: 'TG', label: 'Togo', phone: '228' },
    { id:217,code: 'TH', label: 'Thailand', phone: '66' },
    { id:218,code: 'TJ', label: 'Tajikistan', phone: '992' },
    { id:219,code: 'TK', label: 'Tokelau', phone: '690' },
    { id:220,code: 'TL', label: 'Timor-Leste', phone: '670' },
    { id:221,code: 'TM', label: 'Turkmenistan', phone: '993' },
    { id:222,code: 'TN', label: 'Tunisia', phone: '216' },
    { id:223,code: 'TO', label: 'Tonga', phone: '676' },
    { id:224,code: 'TR', label: 'Turkey', phone: '90' },
    { id:225,code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { id:226,code: 'TV', label: 'Tuvalu', phone: '688' },
    { id:227,code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { id:228,code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { id:229,code: 'UA', label: 'Ukraine', phone: '380' },
    { id:230,code: 'UG', label: 'Uganda', phone: '256' },
    { id:231,code: 'US', label: 'United States', phone: '1', suggested: true },
    { id:232,code: 'UY', label: 'Uruguay', phone: '598' },
    { id:233,code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { id:234,code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { id:235,code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { id:236,code: 'VE', label: 'Venezuela', phone: '58' },
    { id:237,code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { id:238,code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { id:239,code: 'VN', label: 'Vietnam', phone: '84' },
    { id:240,code: 'VU', label: 'Vanuatu', phone: '678' },
    { id:241,code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { id:242,code: 'WS', label: 'Samoa', phone: '685' },
    { id:243,code: 'XK', label: 'Kosovo', phone: '383' },
    { id:244,code: 'YE', label: 'Yemen', phone: '967' },
    { id:245,code: 'YT', label: 'Mayotte', phone: '262' },
    { id:246,code: 'ZA', label: 'South Africa', phone: '27' },
    { id:247,code: 'ZM', label: 'Zambia', phone: '260' },
    { id:248,code: 'ZW', label: 'Zimbabwe', phone: '263' },
  ];