/** @format */

import React from "react";
// Customizable Area Start
import { Modal, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import "./NavbarDropdownModal.scss";
import NavbarDropdownController, { Props } from "./NavBarDropdownController";
import { notificationSound } from "./assets";
// Customizable Area End


// Customizable Area Start
class NavbarDropdownModal extends NavbarDropdownController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        {/* <Modal
          className="modal-container"
          open={this.props.open}
          onClose={this.props.onClose}
          disableScrollLock={true}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          hideBackdrop={true}
        >
        
        </Modal> */}
        {this.props.open &&
          <>
          <div className="backdrop" onClick={this.props.onClose}>j</div>
          <div className={`modal-content position ${this.props.context.isDark ? "dark-color" : ""}`} >
            <Typography className={`${this.props.context.isDark ? "color-white" :""}`} component="p" >
              Welcome {this.props.data.first_name} {this.props.data.last_name}
            </Typography>
            <Typography className={`menu-items ${this.props.context.isDark ? "color-white" :""}`}  component="div">
              <AccountCircleIcon />
              <Typography component="span">Profile</Typography>
            </Typography>
            <Typography className={`menu-items ${this.props.context.isDark ? "color-white" :""}`}  component="div">
              <LocalActivityIcon />
              <Typography  component="span">Activity Log</Typography>
            </Typography>
            <Typography
              onClick={this.onSettingClick}
              data-test-id="setting-click"
              className={`menu-items ${this.props.context.isDark ? "color-white" :""}`}
              component="div"
            >
              <SettingsIcon />
              <Typography component="span">Settings</Typography>
            </Typography>
            <Typography
            data-test-id="logout-click"
              onClick={this.logOut}
              className={`menu-items ${this.props.context.isDark ? "color-white" :""}`}
              component="div"
            >
              <ExitToAppIcon />
              <Typography component="span">Logout</Typography>
            </Typography>
          </div>
          </>}
        <audio ref={this.audioRef} controls={false}>
          <source src={notificationSound} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </>
    );
  }
}
export default NavbarDropdownModal;

// Customizable Area End
