/** @format */

import React from "react";
// Customizable Area Start
import { Button, Grid, Typography } from "@material-ui/core";
import ChatBoxCard from "./ChatBoxCard.web";
import Navbar from "./Navbar.web";
import DashboardContent from "./DashboardContent.web";
import GameSection from "./GameSection.web";
import DashboardControllerWeb, { Props } from "./DashboardControllerWeb";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start

export default class DashboardFullChat extends DashboardControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      <>
        <DashboardSidebar context={this.context} history={this.props.navigation} />
      <Grid container className="main-container">
        <Grid item xs={12} sm={12} md={12} lg={12} className="container-items">

          <Navbar title="Welcome"navigation={this.props.navigation} />

          <Grid container>
            <Grid item xs={12} sm={6} md={7} lg={7}>
              <div className="dashboard-container md">
                <DashboardContent />
                <GameSection />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={5}>
              <div className="chat-graph-conatiner">
                <div className="chat-box">
                  <div className="nav-header">
                    <Typography className="activity" variant="h5">
                      Friends Suggestions
                    </Typography>
                    <Button>View all</Button>
                  </div>
                  <div className="chat-container">
                    {this.state.userData.map((item: any) => (
                      <div className="chat-card-main" key={item.id}>
                        <ChatBoxCard item={item} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </>
    );
  }
}

// Customizable Area End
