/** @format */

import React from "react";
// Customizable Area Start
import OwnedNftController, { Props } from "./OwnedNftController";
import { Grid } from "@material-ui/core";
import "./UserActivity.scss";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class OwnedNft extends OwnedNftController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	render() {
		return (
			<div className="nft-detail">
				<h1>0 Nft</h1>
			</div>
		);
	}
}
export default OwnedNft;

// Customizable Area End
