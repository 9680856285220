import React from "react";
// Customizable Area Start
import {
  Grid,
} from "@material-ui/core";
import { search } from "./assets";
import Button from '@material-ui/core/Button';
import ActivityFeedController from "./ActivityFeedController";
import "./ActivityFeed.scss";
// Customizable Area End

// Customizable Area Start
class ChatList extends ActivityFeedController {

  render() {
    return (
      <div className="chat-list-main-class">

        <Grid container>
          <Grid item xs={12}>
            <div className="app--create-post-form-field-button">
              <Button className={`app-create-post-form-button ${this.context.isDark ? "btn-color color-white" : ''}`} variant="contained" style={{ alignSelf: 'center', borderRadius: 29, marginTop: 20, marginBottom: 10 }} onClick={() => { }}>New Chat</Button>
            </div>
          </Grid>
        </Grid>

        <div style={{ width: '95%', position: 'relative' }} className="add-post-class">
          <input type={'text'} onChange={() => { }} placeholder="Search people" className={`search-user-chat-list ${this.context.isDark ? "textfield-color" : ''}`} />
          <div className="input-field-search-icon" style={{ alignItems: 'center', justifyItems: 'center' }}>
            <img
              style={{ height: 18, width: 18, marginRight: 10 }}
              src={search}
            />
          </div>
        </div>
        <p className="pointer-class" style={{ alignSelf: 'flex-end', marginRight: 20, color: '#4D2E91' }}>New Group</p>
        <div className="chat-list-scroll-class scroller">
          {this.state.userData.map((item: any, index: any) => (
            <div key={item.name} className={index == 0 ? "chat-list-selected" : "chat-list"}>
              <div className="chat-card-sec">
                <div className="profile-img-container">
                  <img
                    src={item.profileImg}
                    alt={item.name}
                  />
                  <span className="dots" />
                </div>
                <div style={{ flex: 1 }} className="profile-detail">
                  <h3 className="profile-name">{item.name}</h3>
                  <p style={{ fontSize: 12, color: '#AFBBC6', marginTop: 3 }}>{item.status}</p>
                </div>
              </div>
              <div className="chat-count-main-class">
                {index != 0 ? <p style={{ fontSize: 12, color: '#7B8793' }}>30.01.21</p> :
                  <div style={{ borderRadius: 10, alignSelf: 'center', justifyContent: 'center', flexDirection: 'row' }} className="chat-count-class"><h6 style={{ color: 'white', fontSize: 13 }}>3</h6> </div>
                }
              </div>
            </div>
          ))}
        </div>

      </div>
    );
  }
}

export default ChatList;
// Customizable Area End