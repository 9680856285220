// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  context:any;
  // Customizable Area Start
  test?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appearenceSettings: {
    mode: boolean;
  
  };
  openmode: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class AppearenceController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiChangeApperanceSettings: string = "";
  getApperanceApiId: string = "";
  apiForRequestCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      appearenceSettings: {
        mode: false,
      
      },
      openmode:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): any {
    // Customizable Area Start
    this.getApperanceSettings()
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const token = localStorage.getItem("accessToken");
    return token;
  };
  handleCloseMode = () => {
    this.setState({openmode:false})
  }
  getApperanceSettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };


    const messageRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getApperanceApiId = messageRequest.messageId;

    messageRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSoundSettingApiEndpoint
    );

    messageRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    messageRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodForSoundSetting
    );

    runEngine.sendMessage(messageRequest.id, messageRequest);

    return true;
  }

  handleAppearencesettings = (key: string) => {
    let appearenceSettings = { ...this.state.appearenceSettings };
    //@ts-ignore
    appearenceSettings[key] = !appearenceSettings[key];

    this.setState({
      appearenceSettings,
    });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };

    const attrs = {
      //@ts-ignore
      "mode": appearenceSettings[key]
    };

    const data = {
      "attributes": attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessageForApperance = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangeApperanceSettings = requestMessageForApperance.messageId;

    requestMessageForApperance.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeAppearenceSettingApiEndpoint
    );

    requestMessageForApperance.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForApperance.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageForApperance.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessageForApperance.id, requestMessageForApperance);

    return true;
  };

  handleChangeApperanceSettingResponse = (from: string, message: Message) => {
    const apiForRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiForRequestCallId != null) {
      if (
        apiForRequestCallId === this.apiChangeApperanceSettings &&
        responseJson !== undefined
      ) {
        this.props?.context?.updateDarkMode(this.state.appearenceSettings.mode)
        this.setState({openmode:true})
      }
    }
  }

  handleGetApperanceResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.getApperanceApiId &&
        responseJson !== undefined
      ) {
        delete responseJson.data.attributes.account_id;
        delete responseJson.data.attributes.sound_for_media;
        delete responseJson.data.attributes.sound_for_notifications;
        delete responseJson.data.attributes.sound_for_open_close;
        delete responseJson.data.attributes.sound_for_sound_effects;
        delete responseJson.data.attributes.login_with_pin;
        this.props?.context?.updateDarkMode(responseJson.data.attributes.mode)
        this.setState({appearenceSettings: {...responseJson.data.attributes}})
      }
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleChangeApperanceSettingResponse(from, message);
    this.handleGetApperanceResponse(from, message)
    // Customizable Area End
  }
}
// Customizable Area End
