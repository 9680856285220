// Customizable Area Start
//@ts-ignore
import React from "react";
import Button from '@material-ui/core/Button';
import './EmailAccountLoginBlock.scss'
import EmailLoginAccountController, {
    Props
} from "./LoginEmailAccountController";
import Snackbar from '@material-ui/core/Snackbar'
import Sidebar from "../../../components/src/Sidebar.web";
import ThemeContext from '../../../components/src/ThemeContext.web';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start

export default class SecurityCode extends EmailLoginAccountController {

    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        return (
            <>
                <Sidebar from="login" history={this.props.navigation} context={this.context} />
                <div className={`${this.context.isDark ? "dark-color" :"snackbar-bg"}`}>
				<Snackbar
			open={this.state.showsnack}
			autoHideDuration={2000}
					onClose={this.handleClose}
			message='OTP sent successfully'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
			</div>
                <div className={`app-page-section ${this.context.isDark ? "dark-color" : ""}`}>
                    <div className="app-page-wrapper">
                        <div className="app-login-form">

                            <h1 className={`app-login-form-title text-align-center ${this.context.isDark ? "color-white" : ""}`} >Enter Security code</h1>
                            <form>
                                <ul className="app-login-form-field-group">

                                    <li>
                                        <input className={`app-login-form-field ${this.context.isDark ? "textfield-color" : ''}`} type={'text'} placeholder="Four-digit security code sent to your email" onChange={(e) => { this.setSecurityCode(e.target.value) }} />
                                    </li>
                                    {localStorage.getItem("from") && <li>
                                        <input className={`app-login-form-field ${this.context.isDark ? "textfield-color" : ''}`} type={'password'} placeholder="Enter new password" onChange={(e) => { this.setPassword(e.target.value) }} />
                                    </li>}

                                    {localStorage.getItem("from") && <li>
                                        <input className={`app-login-form-field ${this.context.isDark ? "textfield-color" : ''}`} type={'password'} placeholder="Confirm password" onChange={(e) => { this.setConfirmPassword(e.target.value) }} />
                                    </li>}
                                    {this.state.errorMessage && <span className="text-center error-text">{this.state.errorMessage}</span>}
                                </ul>
                                <Button className={`app-login-form-button ${this.context.isDark ? "btn-color color-white" : ''}`} variant="contained" onClick={() => {
                                    if (localStorage.getItem("from")) {
                                        this.changePasswordApi();
                                        return
                                    }
                                    this.checkSecurityCode();

                                }}>Next</Button>
                                {<p className={`app-login-form-button-alt ${this.context.isDark ? "color-white" : ""}`}>Dont receive the OTP ? {this.state.counter > 0 && this.state.startTimer ? <span className="resend-otp cursor-pointer">00:{this.state.counter}</span>  :<span className="resend-otp cursor-pointer" onClick={() => { this.resendOtpApi() }}>RESEND OTP</span>}</p>}
                            </form>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

SecurityCode.contextType = ThemeContext;

// Customizable Area End
