/** @format */

import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import Navbar from "../../dashboard/src/Navbar.web";
import "./Settings.scss"
import SettingController, { SettingItems, Props } from "./SettingController";
import PublicProfile from "./PublicProfile.web";
import Sound from "./Sound.web";
import Notification from "./Notification.web";
import SettingContactUs from "./SettingContactus.web";
import AboutUs from "./About.web";
import FAQs from "./FAQs.web";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import Appearances from "./Appearance.web";
import SecuritySettings from "./SecuritySettings.web";
import Currency from "./Currency.web";
import ThemeContext from "../../../components/src/ThemeContext.web";

// Customizable Area End
// Customizable Area Start


// Customizable Area End

// Customizable Area Start
class Settings extends SettingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    renderScreen = () => {
        if (!this.state.currentState.length) {
            return <Notification context={this.context}/>
        }
        switch (this.state.currentState) {
            case "profile":
                return <PublicProfile context={this.context} navigation={this.props.navigation} sendProfileData={this.getDataFromPublicProfile} />;
            case "sound":
                return <Sound context={this.context}/>;
            case "contact_us":
                return <SettingContactUs context={this.context} navigation={this.props.navigation} />;
            case "about":
                return <AboutUs context={this.context}/>;
            case "faqs":
                return <FAQs context={this.context}/>;
            case "appearance":
                return <Appearances context={this.context} />;
            case "security_settings":
                return <SecuritySettings context={this.context} navigation={this.props.navigation}/>;
            case "preferred_currency":
                return <Currency context={this.context}/>;
            default:
                return <Notification context={this.context}/>;
        }
    }

    render() {
        return (
            <>
      
                <DashboardSidebar context={this.context} active={false} history={this.props.navigation}/>
                <Grid container className={`setting-container app-page-dashboard ${this.context.isDark ? "dark-color" :""}`}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={`setting-items ${this.context.isDark ? "dark-color" :""}`}>
                        <Navbar context={this.context} title="Settings" navigation={this.props.navigation} userData={this.state.userData} />
                        <Grid container className="setting-menu-profile">
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                {SettingItems.map((item: any) => (
                                    <div data-test-id={`setting-sidedrawer-click_${item.id}`} onClick={() => this.handleButtonClick(item.path)} className={this.getClassName(item)} key={item.id}>
                                        <p className={`${this.context.isDark ? "color-white" :""}`}>{item.title}</p>
                                    </div>
                                ))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                {this.renderScreen()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>

        );
    }
}
export default Settings;

Settings.contextType = ThemeContext;

// Customizable Area End
