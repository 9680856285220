import React from "react";

// Customizable Area Start
import Sidebar from "../../../components/src/Sidebar.web";

import './ForgotPassword.scss'
import OTPInputAuthController, {
  Props
} from "../../otp-input-confirmation/src/OTPInputAuthController";
import ThemeContext from '../../../components/src/ThemeContext.web';
import { verify } from "./assets";
// Customizable Area End
 // Customizable Area Start
export default class ForgotPasswordOTP extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }
 
  render() {
    return (
      <>
        <Sidebar from="login" history={this.props.navigation} context={this.context} />
        <div className={`app-page-section ${this.context.isDark ? "dark-color" : ""}`}>
          <div className="app-page-wrapper">
            <div>
              <img src={verify} className="img-style" />
              <h1 className={`app-login-form-title ${this.context.isDark ? "color-white" : ""}`}>Email Sent</h1>
              <h6 className={`app-login-form-subtitle text-align-center ${this.context.isDark ? "color-white" : ""}`}>Check your email and open the link we sent to continue</h6>
            </div>
          </div>
        </div>

      </>
    );
  }
}

ForgotPasswordOTP.contextType = ThemeContext;


// Customizable Area End
