/** @format */

import React from "react";
// Customizable Area Start
import FAQController, {Props} from "./FAQController";
import "./Faqs.scss"
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class FAQs extends FAQController {
	constructor(props: Props) {
		super(props)
	}
	render() {
		return (
			<div className="faqs">
				<h1 className={`${this.props.context.isDark ? "color-white" :""}`} >FAQs</h1>
				<div className="faq-sec">
					{this.state.faqData?.map((faq: any) => (
						<div className="faq-inner-sec" key={faq.id}>
							<h3 className={`${this.props.context.isDark ? "color-white" :""}`} >{faq.title}</h3>
							<p className={`${this.props.context.isDark ? "color-white" :""}`} >{faq.content}</p>
						</div>
					))}
				</div>
			</div>
		);
	}
}
export default FAQs;

// Customizable Area End
