import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { PersonasAvatar } from "./personas-avatar";
import SelectOptionsAvatar from "./SelectOptionsAvatar";
import {
  skinColors,
  hairColors,
  hairs,
  facialHairs,
  facialHairColors,
  bodies,
  bodyColors,
  eyeses,
  mouths,
  noses,
  backgroundColors,
} from "./personas-avatar/constants";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import EditAvatarController, {
  Props,
  configJSON,
} from "./EditAvatarController";

import { toPng } from "html-to-image";

export default class EditAvatar extends EditAvatarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.avatar = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  onCapture = () => {
    let avatar = document.getElementById("avatar");
    if (avatar) {
      toPng(avatar)
        .then((dataUrl) => {
          // console.log(dataUrl)
          // const link = document.createElement('a')
          // link.download = 'avatar.png'
          // link.href = dataUrl
          // link.click();
          this.props.onSave(dataUrl, this.getCharacter());
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box style={webStyle.avatarIconWrapper}>
            <Box id="avatar" style={webStyle.avatarPng}>
              <PersonasAvatar
                ref={this.avatar}
                characters={this.getCharacter()}
                onNewCharacters={this.randomCharacter}
                style={webStyle.persionalAvatar}
              />
            </Box>
          </Box>
          <SelectOptionsAvatar
            type={"skinColors"}
            title={configJSON.selectSkinTitle}
            selectedColor={this.state.skinColor}
            colors={skinColors}
            onColorPress={(value: any) => this.setState({ skinColor: value })}
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <SelectOptionsAvatar
            type={"hairs"}
            title={configJSON.selectHairTitle}
            selectedIcon={this.state.hair}
            selectedColor={this.state.hairColor}
            icons={hairs}
            colors={hairColors}
            onIconPress={(value: any) => this.setState({ hair: value })}
            onColorPress={(value: any) => this.setState({ hairColor: value })}
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <SelectOptionsAvatar
            type={"facialHairs"}
            title={configJSON.selectFacialHairTitle}
            selectedIcon={this.state.facialHair}
            selectedColor={this.state.facialHairColor}
            icons={facialHairs}
            colors={facialHairColors}
            onIconPress={(value: any) => this.setState({ facialHair: value })}
            onColorPress={(value: any) =>
              this.setState({ facialHairColor: value })
            }
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <SelectOptionsAvatar
            type={"body"}
            title={configJSON.selectBodyTitle}
            selectedIcon={this.state.body}
            selectedColor={this.state.bodyColor}
            icons={bodies}
            colors={bodyColors}
            onIconPress={(value: any) => this.setState({ body: value })}
            onColorPress={(value: any) => this.setState({ bodyColor: value })}
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <SelectOptionsAvatar
            type={"eyes"}
            title={configJSON.selectEyesTitle}
            selectedIcon={this.state.eyes}
            icons={eyeses}
            onIconPress={(value: any) => this.setState({ eyes: value })}
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <SelectOptionsAvatar
            type={"mouths"}
            title={configJSON.selectMouthTitle}
            selectedIcon={this.state.mouth}
            icons={mouths}
            onIconPress={(value: any) => this.setState({ mouth: value })}
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <SelectOptionsAvatar
            type={"noses"}
            title={configJSON.selectNoseTitle}
            selectedIcon={this.state.nose}
            selectedColor={skinColors[this.state.skinColor]}
            icons={noses}
            onIconPress={(value: any) => this.setState({ nose: value })}
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <SelectOptionsAvatar
            type={"backgroundColors"}
            title={configJSON.selectBgcTitle}
            colors={backgroundColors}
            selectedColor={skinColors[this.state.skinColor]}
            onColorPress={(value: any) => this.setState({ bgc: value })}
            open={this.state.open}
            openToggle={(opn: string) => this.setState({ open: opn })}
          />

          <Box style={webStyle.btnWrapper}>
            <Button
              onClick={() => this.avatar.current.randomize()}
              variant="contained"
              color="primary"
              style={webStyle.btn}
            >
              {configJSON.randomBtnTitle}
            </Button>
            <Button
              onClick={this.onCapture}
              variant="contained"
              color="primary"
              style={webStyle.btn}
            >
              {configJSON.saveBtnTitle}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row" as "row",
    paddingTop: 15,
  },
  btn: {
    marginLeft: 10,
  },
  avatarIconWrapper: {
    width: 180,
    margin: "0 auto",
    marginTop: 20,
  },
  avatarPng: {
    backgroundColor: "transparent",
    width: 180,
  },
  persionalAvatar: {
    alignSelf: "center" as "center",
    height: 180,
    width: 180,
  },
};
// Customizable Area End
