/** @format */

import React from "react";
// Customizable Area Start
import PlayerActivityController, {
	Props,
} from "./PlayerActivityController";
import { Grid } from "@material-ui/core";
import "./UserActivity.scss";
import ChatBoxCard from "../../dashboard/src/ChatBoxCard.web";
import { gameImage } from "./assets";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class PlayerActivity extends PlayerActivityController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	render() {
		return (
			<div className="player-activity faq-sec width-fixed">
				<h2>Player Activity</h2>
				{this.state.playerdata.map((item) => (
					<div key={item.id} className="player-activity-detail">
						<ChatBoxCard item={item.cardItem} />
						<div className="player-post">
							<div className="game-image">
								<img src={item.image} alt="game" />
								<div className="floating-div">
									<div
										className="favourite-icon"
										onClick={() => this.clickforFavourite(item.id)}>
										{this.state.isActive === item.id &&
										this.state.isFavourite ? (
											<FavoriteIcon className="icon-favourite" />
										) : (
											<FavoriteBorderIcon className="icon-favourite-border" />
										)}
									</div>

									<button>Play Game</button>
								</div>
							</div>
							<p>{item.description}</p>
						</div>
					</div>
				))}
			</div>
		);
	}
}
export default PlayerActivity;

// Customizable Area End
