// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    title?: string;
    open?: any;
    onClose?:any;
    context?:any;
    userData?:any;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
	open: boolean;
	profileData: any;
	defaultImage:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start

// Customizable Area End

export default class NavbarController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    profileDetailCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
			open: false,
			profileData: {},
			defaultImage:
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
		this.getProfileDetail();
        // Customizable Area End  
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if(prevProps?.userData !== this.props?.userData) {
            if(this.props.userData) {
                this.setState({profileData: {
                    ...this.state.profileData,
                    attributes: this.props.userData,
                }})
            }
        }
    }
    // Customizable Area Start
    handleOpenModal () {
        this.setState({ open: !this.state.open })
	}
	onClose = () => {
		this.setState({ open: false });
	}
	logOut = () => {
        localStorage.clear();
		this.props.navigation.navigate("EmailAccountLoginBlock")
	}
	onSettingClick = () => {
		this.props.navigation.history.push("setting")
	}
	handleNavigate = () => {
		this.props.navigation.navigate("Dashboard")
    }
    onActivityClick = () => {
		this.props.navigation.history.push("Activity")
    }
	getToken = () => {
        const token = localStorage.getItem("accessToken")
        return token
    }
    getProfileDetail = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token:  this.getToken()
        };

        const profileDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.profileDetailCallId = profileDataMessage.messageId;

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.profileDetailApiEndPoint
        );

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        profileDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.profileDetailApiMethodType
        );

        runEngine.sendMessage(profileDataMessage.id, profileDataMessage);
    }

    handleResponseForProfileDetail = (from: string, message: Message) => {
        
        if (this.profileDetailCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                console.log(apiResponse.errors)


            } else {
                this.props?.context?.updateDarkMode(apiResponse.data?.attributes?.mode)
				this.setState({ profileData: apiResponse.data })
            }
        }
    }

    // Customizable Area End  

    async receive(from: string, message: Message) {
        
        // Customizable Area Start
        this.handleResponseForProfileDetail(from, message)
        // Customizable Area End
    }

}
