/** @format */

import React from "react";
// Customizable Area Start
import Chart from "react-apexcharts";
import DashboardControllerWeb, { Props } from "./DashboardControllerWeb";
import "./DonutChart.scss";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class DonutChart extends DashboardControllerWeb {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	reducerFunc = (w:any) =>{
		return w.globals.seriesTotals.reduce(
			(a: number, b: number) => {
				return a + b;
			},
			0
		);
	}
	textDataFormatter= (legendName: string, opts?: any)=> {
		return `<p>${legendName} </p> 
				<p class="legend-text">
					${opts.w.globals.series[opts.seriesIndex]}
				</p>
				`;
	}
	render() {
		return (
			<div className={`donut-chart ${this.props.context.isDark ? "dark-side-color" :""}`}>
				<Chart
					plugins="Total Amount"
					type="donut"
					width="100%"
					height="300px"
					series={[3002, 5098, 4003, 2001]}
					options={{
						labels: ["Bitcoin", "Ethereum", "Achain", "Other"],

						stroke: {
							width: 0,
						},
						colors: ["#6F2290", "#443191", "#40035A", "#E098FF"],
						plotOptions: {
							pie: {
								expandOnClick: false,
								customScale: 0.9,

								donut: {
									size: "85%",
									labels: {
										show: true,
										total: {
											label: "Total Balance",
											show: true,
											showAlways: true,
											formatter:this.reducerFunc ,
											fontSize: "1.4rem",
											color:this.props.context.isDark ? 'white': "#030303",
										},
									},
								},
							},
						},
						legend: {
							show: true,
							showForSingleSeries: true,
							showForNullSeries: true,
							showForZeroSeries: true,
							position: "right",
							horizontalAlign: "center",
							floating: false,
							fontSize: "14px",
							fontWeight: 400,

							formatter: this.textDataFormatter,
							inverseOrder: false,
							width: undefined,
							height: undefined,
							tooltipHoverFormatter: undefined,
							customLegendItems: [],
							offsetX: 0,
							offsetY: 0,
							labels: {
								colors: undefined,
								useSeriesColors: true,
							},
							markers: {
								width: 4,
								height: 20,
								strokeWidth: 0,
								strokeColor: "#fff",
								fillColors: undefined,
								radius: 12,
								customHTML: undefined,
								onClick: undefined,
								offsetX: 0,
								offsetY: 0,
							},
							itemMargin: {
								horizontal: 5,
								vertical: 0,
							},
							onItemClick: {
								toggleDataSeries: true,
							},
							onItemHover: {
								highlightDataSeries: true,
							},
						},

						dataLabels: {
							enabled: false,
						},
					}}
				/>
			</div>
		);
	}
}
export default DonutChart;

// Customizable Area End
