  // Customizable Area Start
//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  Typography,
  Switch
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { data, webStyle } from "./IsoData"
import Snackbar from '@material-ui/core/Snackbar'
import { withStyles } from '@material-ui/core/styles';

export const themeFunc = data

  export const switchFunc = ({ classes, ...props }) => {
    return (
      <Switch
        data-test-id="my-switch-id"
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  }
export const IOSSwitch = withStyles(themeFunc)(switchFunc);
// Customizable Area End

import AppearenceController, {
  Props,
} from "./AppearenceController";

export default class Appearances extends AppearenceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPickerElement({ title, key }: any) {
    return (
      <Box sx={webStyle.pickerWrapper}>
        <Box component="div" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='body2' className={`${this.props.context.isDark ? "color-white" :""}`} style={webStyle.soundsettingTitle}>{title}</Typography>
          <Typography variant='body2' className={`${this.props.context.isDark ? "color-white" :""}`}>Changing the appearance of the app</Typography>
        </Box>
        <Box className="iso_switch" sx={{ width: 80 }}>
        <IOSSwitch data-test-id={`switch-btn-${key}`} checked={this.state.appearenceSettings[key]} onChange={() => this.handleAppearencesettings(key)} name="checkedB" />
        </Box>
      </Box>
    )
  }
  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <>
        <div className={`${this.props.context.isDark ? "color-white" :"snackbar-bg"}`}>
				<Snackbar
			open={this.state.openmode}
			autoHideDuration={2000}
					onClose={this.handleCloseMode}
			message='mode changed successfully!'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
        </div>
        <Container maxWidth={"sm"}>
          <Box className={`${this.props.context.isDark ? "background-none" :""}`} sx={webStyle.mainWrapper}>
            <Typography variant="h4" className={`${this.props.context.isDark ? "color-white" :""}`} style={webStyle.settingTitle}>Appearance</Typography>
            <p className={`${this.props.context.isDark ? "color-white" :""}`} style={webStyle.settingDescription}>
            Changing the appearance of the app
            </p>
            <Box style={{ marginTop: "15px" }}>
              {this.renderPickerElement({
                title: "Mode",
                key: 'mode'
              })}
            </Box>
          </Box>
        </Container>
      </>
        
      // Customizable Area End
    );
  }
}
  // Customizable Area End
  
// Customizable Area Start

// Customizable Area End
