// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {createRef} from 'react'
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  test?: any;
  context:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  soundSettings: {
    sound_for_open_close: boolean;
    sound_for_notifications: boolean;
    sound_for_media: boolean;
    sound_for_sound_effects: boolean;
  };
  isPlaying: boolean,
  openToastforSound: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class SettingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiChangeSoundSettings: string = "";
  getSoundApiId: string = "";
  audioRef: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      soundSettings: {
        sound_for_open_close: false,
        sound_for_notifications: false,
        sound_for_media: false,
        sound_for_sound_effects: false,
      },
      isPlaying: false,
      openToastforSound: false,
    };
    this.audioRef = createRef()
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handlePlayPause = () => {
    if (this.audioRef.current) {
      if (this.state.isPlaying) {
        this.audioRef.current.pause();
        this.setState({isPlaying: false});
      } else {
        this.audioRef.current.play();
        this.setState({isPlaying: true});
      }
    }
  };

  componentDidMount(): any {
    // Customizable Area Start
    this.getSoundSettings()
    // Customizable Area End
  }
  // Customizable Area Start
  getToken = () => {
    const token = localStorage.getItem("accessToken");
    return token;
  };
  handleCloseToast=()=> {
    this.setState({openToastforSound: false});
    }
  getSoundSettings = () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSoundApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSoundSettingApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethodForSoundSetting
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleSoundSettings = (key: string) => {
    let soundSettings = { ...this.state.soundSettings };
    //@ts-ignore
    soundSettings[key] = !soundSettings[key];

    //play sound when seeting got changed
    if(key === 'sound_for_open_close' && soundSettings[key]) {
      this.handlePlayPause();
      this.setState({isPlaying: false});
    }

    this.setState({
      soundSettings,
    });

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": this.getToken(),
    };

    const attrs = {
      //@ts-ignore
      [key]: soundSettings[key]
    };

    const data = {
      "attributes": attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangeSoundSettings = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeSoundSettingApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleChangeSoundSettingResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.apiChangeSoundSettings &&
        responseJson !== undefined
      ) {
        this.setState({openToastforSound: true})
      }
    }
  }

  handleGetSoundSettingsResponse = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId != null) {
      if (
        apiRequestCallId === this.getSoundApiId &&
        responseJson !== undefined
      ) {
        delete responseJson.data.attributes.account_id;
        this.setState({soundSettings: responseJson.data.attributes})
      }
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleChangeSoundSettingResponse(from, message);
    this.handleGetSoundSettingsResponse(from, message)
    // Customizable Area End
  }
}
// Customizable Area End
