//Customizable Area Start
import React from "react";
import ForgotPasswordController, { Props } from "./PasswordForgotController";
import Button from '@material-ui/core/Button';
import Sidebar from "../../../components/src/Sidebar.web";
import ThemeContext from '../../../components/src/ThemeContext.web';
//Customizable Area End


//Customizable Area Start
export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
       <Sidebar from="login" history = {this.props.navigation} context={this.context}/>
        <div className={`app-page-section ${this.context.isDark ? "dark-color" :""}`}>
          <div className="app-page-wrapper">
            <div className="app-login-form">

              <h1 className={`app-login-form-title text-align-center ${this.context.isDark ? "color-white" :""}`}>Reset Your Password</h1>
              <h6 className={`app-login-form-subtitle text-align-center ${this.context.isDark ? "color-white" :""}`}>Enter your email and we’ll send you a link to reset your password.</h6>
              <form>
                <ul className="app-login-form-field-group">

                  <li>
                    <input className={`app-login-form-field ${this.context.isDark? "textfield-color":''}`} placeholder="Enter email address"  onChange={(e:any) => {this.setEmail(e.target.value)}}/>
                  </li>
                </ul>
                <Button className={`app-login-form-button ${this.context.isDark? "btn-color color-white":''}`} variant="contained" onClick={() => {

                 this.goToOtpAfterEmailValidation()

                }}>Next</Button>

              </form>
            </div>
          </div>
        </div>

      </>
    );
  }
}


ForgotPassword.contextType =  ThemeContext;

 //Customizable Area End