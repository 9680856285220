// Customizable Area Start
//@ts-nocheck
import React from "react";

import {
  Container,
  Box,
  Typography,
  Switch,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { data, webStyle } from "./IsoData";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from '@material-ui/core/Snackbar'
export const IOSSwitch = withStyles(data)(({ classes, ...props }) => {
  return (
    <Switch
      data-test-id="my-switch-id"
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
// Customizable Area End

import SoundSettingsController, { Props } from "./SoundController";
import { notificationSound } from "./assets";

export default class SoundSettings extends SoundSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPickerElement({ title, key }: any) {
    return (
      <Box sx={webStyle.pickerWrapper}>
        <Box
          className="md-iso"
          component="div"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography
            variant="body2"
            style={webStyle.soundsettingTitle}
            className={`pd-iso ${this.props.context.isDark ? "color-white" :""}`}
          >
            {title}
          </Typography>
        </Box>
        <Box className="iso_switch" sx={{ width: 80 }}>
          <IOSSwitch
            data-test-id={`switch-btn-${key}`}
            checked={this.state.soundSettings[key]}
            onChange={() => this.handleSoundSettings(key)}
            name="checkedB"
          />
        </Box>
      </Box>
    );
  }

  renderPickerElementTwo({ title, key }: any) {
    return (
      <Box className="iso-pd" sx={webStyle.pickerWrapper}>
        <Box
          className="iso-md"
          component="div"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="body2" style={webStyle.soundsettingTitle}>
            {title}
          </Typography>
        </Box>
        <Box className="iso_switch" sx={{ width: 80 }}>
          <IOSSwitch
            data-test-id={`switch-btn2-${key}`}
            checked={this.state.soundSettings[key]}
            onChange={() => this.handleSoundSettings(key)}
            name="checkedB"
          />
        </Box>
      </Box>
    );
  }

  renderPickerElementThree({ title, key }: any) {
    return (
      <Box sx={webStyle.pickerWrapper}>
        <Box
          className="iso-cd"
          component="div"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="body2" style={webStyle.soundsettingTitle}>
            {title}
          </Typography>
        </Box>
        <Box className="iso_switch cdr" sx={{ width: 80 }}>
          <IOSSwitch
            data-test-id={`switch-btn3-${key}`}
            checked={this.state.soundSettings[key]}
            onChange={() => this.handleSoundSettings(key)}
            name="checkedB"
          />
        </Box>
      </Box>
    );
  }

  renderPickerElementFour({ title, key }: any) {
    return (
      <Box sx={webStyle.pickerWrapper}>
        <Box component="div" sx={{ display: "flex", flexDirection: "column" }}>
          <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="body2" style={webStyle.soundsettingTitle}>
            {title}
          </Typography>
        </Box>
        <Box className="iso_switch mr" sx={{ width: 80 }}>
          <IOSSwitch
            data-test-id={`switch-btn4-${key}`}
            checked={this.state.soundSettings[key]}
            onChange={() => this.handleSoundSettings(key)}
            name="checkedB"
          />
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      	<div className={`${this.props.context.isDark ? "color-white" :"snackbar-bg"}`}>
				<Snackbar
			open={this.state.openToastforSound}
			autoHideDuration={2000}
					onClose={this.handleCloseToast}
			message='Sound setting changed successfully!'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				/>
        </div>
        <Container maxWidth={"sm"}>
        <Box sx={webStyle.mainWrapper} className={`${this.props.context.isDark ? "dark-color" :""}`}>
          <Typography  className={`${this.props.context.isDark ? "color-white" :""}`} variant="h4" style={webStyle.settingTitle}>
            Sounds
          </Typography>
          <p className={`${this.props.context.isDark ? "color-white" :""}`} style={webStyle.settingDescription}>
            Turning on and off sounds within the app
          </p>
          <Box style={{ marginTop: "15px" }}>
            {this.renderPickerElement({
              title: "Sounds for opening the app/ closing the app",
              key: "sound_for_open_close",
            })}
            {this.renderPickerElementTwo({
              title: "Sounds for notifications",
              key: "sound_for_notifications",
            })}
            {this.renderPickerElementThree({
              title: "Sounds for media within the app",
              key: "sound_for_media",
            })}
            {this.renderPickerElementFour({
              title: "Sounds for sound effects",
              key: "sound_for_sound_effects",
            })}
          </Box>
        </Box>
        <div>
          <audio ref={this.audioRef} controls={false}>
            <source src={notificationSound} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </Container>
      </>
     
      // Customizable Area End
    );
  }
}
// Customizable Area End

// Customizable Area Start

// Customizable Area End
