/** @format */

import React from "react";
// Customizable Area Start
import ActivityFeedController from "./ActivityFeedController";
import Chat from "./Chat.web";
import ChatList from "./ChatList.web";
import "./ActivityFeed.scss";
// Customizable Area End

// Customizable Area Start
class ChatMainComponent extends ActivityFeedController {
	render() {
		return (
			<div style={{ flex: 1 }} className="chat-main-class scroller scroller-width">
				<ChatList navigation={this.props.navigation} id={""} />

				<Chat navigation={this.props.navigation} id={""} />
			</div>
		);
	}
}

export default ChatMainComponent;
// Customizable Area End
