// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selected: any
  email: string;
  password: string;
  errorMessage: string;
  formErrors: any;
  open: boolean,
  snakbarAlert: any,
  snakbarText: any,
  errorUserName: any,
  errorPassword: any,
  intervalId:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
// Customizable Area Start
export default class SplashScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  adminApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
 
    this.state = {
      email: "",
      password: "",
      errorMessage: "",
      selected: 10,
      formErrors: { userName: '', pass: '' },
      open: false,
      snakbarAlert: false,
      snakbarText: "",
      errorUserName: false,
      errorPassword: false,
      intervalId:"",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const intervalId = setInterval(() => {
      if(localStorage.getItem('entered') != null){
        this.props.navigation.navigate('LockScreen')
        return
      }
       this.props.navigation.navigate('EmailAccountLoginBlock')
      }, 1000);
    this.setState({ intervalId })

  }

 async componentWillUnmount() {
  clearInterval(this.state.intervalId)
  }

}
// Customizable Area End