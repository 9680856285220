export const data = (theme:any) => ({
    root: {
      width: "96px",
      height: "48px",
      margin: "8px",
      padding: 0,
      borderRadius: "25px"
  
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(48px)',
        color: "#fff !important",
        '& + $track': {
          backgroundColor: '#e7e4f1',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
      },
    },
    thumb: {
      width: 32,
      height: 32,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: "#e7e4f1",
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
})
  
export const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  settingTitle: {
    font: 'normal normal bold 38px/36px Gilroy',
    color: '#1D1350',
  },
  settingDescription: {
    font: 'normal normal normal 22px/36px Gilroy',
    color: '#1D1350',
    opacity: 0.76,
    marginTop: '20px'
  },

  pickerWrapper: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    margingBottom: 20
  },
  soundsettingTitle: {
    width: '373px',
    font: 'normal normal bold 22px/36px Gilroy',
    color: '#1D1350',
    marginBottom: '5px'
  },
  soundsettingSubTitle:{
    width: '99px',
    font: "normal normal medium 18px/27px Gilroy",
    color: '#1D1350',
    marginBottom: '5px',
    cursor:'pointer',
    textDecoration:'underline'
  }
};