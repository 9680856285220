import React from 'react'
// Customizable Area Start
import Button from '@material-ui/core/Button';
import { esfera_logo } from '../../blocks/email-account-login/src/assets';
import DashbordIcon from "@material-ui/icons/Dashboard";
// Customizable Area End

// Customizable Area Start
const sidebarContent = [{
  id: 1,
  name: 'Dashboard',
  icon: <DashbordIcon className='icon-style' />,
  path: 'Dashboard'
},
{
  id: 2,
  name: 'Portfolio',
  icon: <DashbordIcon className='icon-style' />,
  path: 'Dashboard'
},
{
  id: 3,
  name: 'Launcher',
  icon: <DashbordIcon className='icon-style' />,
  path: 'Dashboard'
},
{
  id: 4,
  name: 'Crypto Market',
  icon: <DashbordIcon className='icon-style' />,
  path: 'Dashboard'
},
{
  id: 5,
  name: 'NFT Market',
  icon: <DashbordIcon className='icon-style' />,
  path: 'NftMarket'
},
{
  id: 6,
  name: 'Store',
  icon: <DashbordIcon className='icon-style' />,
  path: 'Dashboard'
},
{
  id: 7,
  name: 'Activity',
  icon: <DashbordIcon className='icon-style' />,
  path: 'ActivityFeed'
},
{
  id: 8,
  name: 'Exchange',
  icon: <DashbordIcon className='icon-style' />,
  path: 'Dashboard'
},
{
  id: 9,
  name: 'Friends',
  icon: <DashbordIcon className='icon-style' />,
  path: 'Dashboard'
},
]
// Customizable Area End

// Customizable Area Start
const DashboardSidebar = ({ history, selected, context }: any) => {
  const curveClassname=context.isDark ?'inverted-curve-dark' : 'inverted-curve'

  return (
    <div className="app-sidebar overflow-cuatom">
      <div className="app-sidebar-logo">
        <img src={esfera_logo} />
      </div>
      <div className="app-sidebar-menu">
        <ul>
         {sidebarContent.map((item: any) => (
           <li key={item.name}>
           <Button className={selected == item.name ? 'app-sidebar-menu-button active' : `app-sidebar-menu-button`} onClick={() => { history.history.push(item.path) }}>
             {item.icon}
             {item.name}
             <div className={curveClassname}></div>
           </Button>
         </li>
         ))}
        </ul>
      </div>
    </div>
  )
}

export default DashboardSidebar;

// Customizable Area End