import React from "react";

import {
  Container,
  Box,
  Typography,
  Switch
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SettingController, {
  Props,
} from "./SettingController";

export default class Notification extends SettingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPickerElement({title, description, onClick, status}: any) {
    return (
        <Box sx={webStyle.pickerWrapper}>
            <Box component="div" sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="h6" style={{marginBottom: 5, fontWeight: 'bold'}}>{title}</Typography>
                <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="caption">{description}</Typography>
            </Box>
            <Box sx={{width: 80 }}>
                <Switch checked={status}/>
            </Box>
        </Box>
    )
  }
  // Customizable Area End


  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box className={`${this.props.context.isDark ? "background-none" :""}`} sx={webStyle.mainWrapper}>
            <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="h4">Notification</Typography>
            <Typography className={`${this.props.context.isDark ? "color-white" :""}`} variant="subtitle1" component="div">
            We’ll always let you know about important changes, but you pick what else you want to hear about.
            </Typography>
             {this.renderPickerElement({
                title: "On Esfera", 
                description: "Pick which notifications to see while in the app.", 
                onClick:() => {}, 
                status: true})}
             {this.renderPickerElement({
                title: "By email", 
                description: "Pick which notifications to get by email.", 
                onClick:() => {}, 
                status: false})}
             {this.renderPickerElement({
                title: "By push notification", 
                description: "Pick which notifications to get on your computer.", 
                onClick:() => {}, 
                status: true})}
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },

  pickerWrapper: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    margingBottom: 20

  }
};
// Customizable Area End
