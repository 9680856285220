Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Followers";
exports.labelBodyText = "Followers Body";
exports.postApiMethodType = "POST";
exports.deleteApiMethodType = "DELETE";
exports.btnExampleTitle = "CLICK ME";
exports.suggestionListApiEndPoint = "/bx_block_login/logins/suggestion";
exports.followersApiEndPoint = "bx_block_followers/follows";
exports.addFollowersApiEndPoint = "bx_block_followers/follows";
exports.unFollowApisApiEndPoint = `/bx_block_followers/follows`;
exports.followingApiEndPoint = "bx_block_followers/follow/following?id=";
exports.followersApiEndPoint = "/bx_block_followers/follow/followers?id=";
exports.followingText = "Following";
exports.followText = "Follow";
// Customizable Area End