// Customizable Area Start
import React from 'react'
import { esfera_logo } from "./assets"
import "./LockScreen.scss"
import SplashScreenController,{
	Props
  } from "./SplashScreenController";
  import ThemeContext from '../../../components/src/ThemeContext.web';
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start

class SplashScreen extends SplashScreenController {
  
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
      render() {
          return (
              <div className={`lock-screen ${this.context.isDark || localStorage.getItem("darkMode") == "true" ? 'dark-mode-backgroud' :''}`}>
                  <div className="lock-screen-item">
                      <div className="logo">
                          <img className="logo-img" src={esfera_logo} alt="logo" />
                      </div>
                  </div>
              </div>
          );
      }
  }
  SplashScreen.contextType =  ThemeContext;
  export default SplashScreen;

// Customizable Area End
