/** @format */

import React from "react";
// Customizable Area Start
import { Grid } from "@material-ui/core";
import Navbar from "../../dashboard/src/Navbar.web";
import ExploreNftCollectionController, { Props } from "./ExploreNftCollectionController";
import DashboardSidebar from "../../../components/src/DashbordSidebar";
import ThemeContext from "../../../components/src/ThemeContext.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
class ExploreNftCollection extends ExploreNftCollectionController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>
                <DashboardSidebar context={this.context} history={this.props.navigation} selected="NFT Market" />
                <Grid container className={`main-container app-page-dashboard ${this.context.isDark ? "dark-color" : ""}`}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={`container-items ${this.context.isDark ? "dark-color" : ""}`}
                    >
                        <Navbar context={this.context} title="Welcome" navigation={this.props.navigation} />
                        <Grid container>
                            <Grid item xs={12} sm={6} md={7} lg={7}>

                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={5}>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        );
    }
}
export default ExploreNftCollection;

ExploreNftCollection.contextType = ThemeContext;

// Customizable Area End
