import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Characters } from "./personas-avatar/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  onSave: (uri: string, character: string) => void;
  character: string | null;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  body: Characters["body"];
  eyes: Characters["eyes"];
  facialHair: Characters["facialHair"];
  hair: Characters["hair"];
  mouth: Characters["mouth"];
  nose: Characters["nose"];
  bgc: Characters["backgroundColor"];
  skinColor: Characters["skinColor"];
  hairColor: Characters["hairColor"];
  facialHairColor: Characters["facialHairColor"];
  bodyColor: Characters["bodyColor"];
  open: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditAvatarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  avatar: any;
  avatarIcon: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      body: "body2",
      eyes: "eyes1",
      facialHair: "facialHair1",
      hair: "hair3",
      mouth: "mouth5",
      nose: "nose2",
      bgc: "bgc2",
      skinColor: "sc3",
      hairColor: "hc8",
      facialHairColor: "fhc1",
      bodyColor: "bc2",
      open: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getCharacter = () => {
    return `${this.state.body}|${this.state.eyes}|${this.state.facialHair}|${this.state.hair}|${this.state.mouth}|${this.state.nose}|${this.state.bgc}|${this.state.skinColor}|${this.state.hairColor}|${this.state.facialHairColor}|${this.state.bodyColor}`;
  };
  randomCharacter = (charactersStr: string) => {
    const characters: any = charactersStr.toString().split("|");
    characters &&
      this.setState({
        body: characters[0],
        eyes: characters[1],
        facialHair: characters[2],
        hair: characters[3],
        mouth: characters[4],
        nose: characters[5],
        bgc: characters[6],
        skinColor: characters[7],
        hairColor: characters[8],
        facialHairColor: characters[9],
        bodyColor: characters[10],
      });
  };

  componentWillMount = () => {
    if (this.props.character) {
      this.randomCharacter(this.props.character);
    }
  };
  // Customizable Area End
}
