/** @format */

import React from "react";
// Customizable Area Start
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./GameCard.scss";
import DashboardControllerWeb,{Props} from "./DashboardControllerWeb";
// Customizable Area End


// Customizable Area Start
class GameCard extends DashboardControllerWeb {
	constructor(props: Props) {
		super(props);
	  }
	render() {
		return (
			<div  className={`game-box ${this.props.context.isDark ? "dark-side-color" :""}`}>
				<div className="game-image">
					<img src={this.props.item.image} alt={this.props.item.desc} />
					<div className="cancel-icon">
						<CloseIcon />
					</div>
				</div>
				<h5 className={`${this.props.context.isDark ? "color-white" :""}`}>{this.props.item.desc}</h5>
				<Button className={`${this.props.context.isDark ? "color-white" :""}`}>Start Game</Button>
			</div>
		);
	}
}
export default GameCard;

// Customizable Area End
