import React from "react";
import FollowersController, { DataListItem } from "./FollowersController";
// Customizable Area Start
import { imgProfile } from "./assets"
import { Dialog } from "@material-ui/core"
// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export class Followers extends FollowersController {

  render() {
    return (
      
      <div style={styles.container}>
        <div style={styles.scroller}>
          {this.state.listData?.map((item:DataListItem, index) => (
            <div style={styles.chatCardMain} key={item.id}>
              <div style={styles.chatCard}>
                <div onClick={() => this.getCurrentUserFollowersData(item)} style={styles.chatCardSec} data-test-id={`getFollowing_${index}`}>
                  <div>
                    <img
                      src={imgProfile}
                      alt="Profile"
                      style={styles.imageTag}
                    />
                    <span />
                  </div>
                  <div style={styles.profileDetail}>
                    <h3 style={styles.profileDetailHeading}>{item.attributes.first_name}</h3>
                    <p style={styles.para}>{item.attributes.email}</p>
                  </div>
                </div>
             
                {item.attributes.is_follow === "following" ? <button data-test-id={`button12_${item.id}`} style={styles.cursorPointer} onClick={() => this.unFollowFromSuggestion(item.id)}>{configJSON.followingText}</button>:<button data-test-id={`buttonData_${index}`} style={styles.cursorPointer} onClick={() => this.addFromSuggestionList(item.id)}>{configJSON.followText}</button>}
              </div>
            </div>
          ))}
        </div>
        <button data-test-id="buttonCheck" onClick={() => this.getFollowingData(this.props.navigation.history.location.state.state)}>Following</button>
        <button data-test-id="user_follower_btn" onClick={() => this.getCurrentUserFollowersData(this.props.navigation.history.location.state.state)}>Followers</button>
        <div>
          <Dialog open={this.state.openModal} onClose={this.onCloseModal} data-test-id="close_modal_1" style={styles.nodalContainer} >
            <div style={styles.scroller}>
              {this.state.errorMsg ? <h1>{this.state.errorMsg}</h1> : this.state.followingData?.map((item, index: number) => (
                <div style={styles.chatCardMain} data-test-id={`listData_${index}`} key={item.id}>
                  <div data-test-id="heading-sec" style={styles.chatCard}>
                    <div data-test-id="chat-card-sec" style={styles.chatCardSec}>
                      <div>
                        <img
                          data-test-id="profile-image"
                          src={imgProfile}
                          alt="Profile"
                          style={styles.imageTag}
                        />
                      </div>
                      <div data-test-id="profile-container" style={styles.profileDetail}>
                        <h3 data-test-id="profile-header" style={styles.profileDetailHeading}>{item.attributes.first_name}</h3>
                        <p data-test-id="paragraph" style={styles.para}>{item.attributes.email}</p>
                      </div>
                    </div>
                    {item.attributes.is_follow === "following" ? <button data-test-id = {`unFollowUserfromfollowing_${item.id}`} style={styles.cursorPointer} onClick={()=>this.unFollowFromFollowing(item.id)}>{configJSON.followingText}</button>:<button data-test-id={`addFollowerbuttonData_${item.id}`} style={styles.cursorPointer} onClick={() => this.addFromFollowing(item.id)}>{configJSON.followText}</button>}
                  </div>
                </div>
              ))}
            </div>
          </Dialog>
          <Dialog open={this.state.openFollowerModel} onClose={this.onCloseFollowerModal} data-test-id="close_modal_2" style={styles.nodalContainer} >
            <div style={styles.scroller}>
              {this.state.errorMsg ? <h1>{this.state.errorMsg}</h1> : this.state.currentFollowerData?.map((item, index: number) => (
                <div style={styles.chatCardMain} data-test-id={`listData_${index}`} key={item.id}>
                  <div data-test-id="card-chat" style={styles.chatCard}>
                    <div data-test-id="chat-inner-sec" style={styles.chatCardSec}>
                      <div>
                        <img
                          data-test-id="image-container-profile"
                          src={imgProfile}
                          alt="Profile"
                          style={styles.imageTag}
                        />
                      </div>
                      <div data-test-id="detail-profile" style={styles.profileDetail}>
                        <h3 data-test-id="profile-heading" style={styles.profileDetailHeading}>{item.attributes.first_name}</h3>
                        <p data-test-id="profile-para" style={styles.para}>{item.attributes.email}</p>
                      </div>
                    </div>
                   {this.handleOperator(item)}
                  </div>
                </div>
              ))}
            </div>
          </Dialog>

        </div>
        </div>
       
    );
  }
}
export default Followers;
const styles = {
  container: {
    width: "700px",
    overflow: "hidden"
  },
  scroller: {
    margin: "0 auto",
    height: "500px",
    width: "100%",
    overflow: "auto"
  },
  chatCardMain: {
    marginRight: "15px"
  },
  chatCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0.5rem 0"
  },
  chatCardSec: {
    display: "flex",
    alignItems: "center",
    width:"500px"
  },
  profileImageContainer: {
    position: "relative" 
  },
  imageTag: {
    width: "2.6rem",
    height: "2.6rem",
    borderRadius: "50%"
  },
  dots: {
    display: "inline-block",
    width: "9px",
    height: "9px",
    border: "1px solid #f7f2f9",
    background: "#00950a",
    borderRadius: "50%",
    position: "absolute",
    bottom: "5px",
    right: "0px"
  },
  profileDetail: {
    margin: "0 0 0 1rem"
  },
  profileDetailHeading: {
    margin: "0",
    fontSize: "1.1rem"
  },
  para: {
    margin: 0
  },
  cursorPointer: {
    cursor:"pointer"
  },
  nodalContainer: {
    padding: "20px",
    height: "400px !important"
  },
  chatMainSec: {

  }
}

// Customizable Area End