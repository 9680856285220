import React from "react";
// Customizable Area Start
import { Typography, Button } from "@material-ui/core";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import { imgLogo, notificationSound } from "./assets";
import "./LockScreen.scss";
import LockScreenController from "./LockScreenController";
import ThemeContext from "../../../components/src/ThemeContext.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class LockScreen extends LockScreenController {
  render() {
    return (
      <div
        className={`lock-screen ${
          this.context.isDark ? "dark-mode-backgroud" : ""
        }`}
      >
        <div className="lock-screen-item">
          <div className="logo">
            <img className="logo-img" src={imgLogo} alt="logo" />
          </div>
          <div className="content">
            <Typography className="content-inner-heading" variant="h3">
              Welcome Back
            </Typography>
            {Object.keys(this.state.profileData).length === 0 ? (
              <div>Loading....</div>
            ) : (
              <>
                <Typography variant="h1" className="content-title">
                  {this.state.profileData.attributes.first_name}
                </Typography>
                {this.state.profileData.attributes.profile_pic.url === null ? (
                  <img
                    className="profile-img"
                    src={this.state.defaultImage}
                    alt="profile"
                  />
                ) : (
                  <img
                    className="profile-img"
                    src={this.state.profileData.attributes.profile_pic.url}
                    alt="profile"
                  />
                )}
              </>
            )}
          </div>
          <div className="input-sec">
            <input
            value={this.state.securityPin}
              type={this.state.showPin ? "text" : "password"}
              placeholder="Type your security pin"
              onChange={(e) => {
                let num = e.target.value.slice(0,8);
                this.setSecurityCode(num);
              }}
            />
            {this.state.showPin ? (
              <VisibilityOffIcon
                className="visibility-icon"
                onClick={() => this.handleShowPin(false)}
              />
            ) : (
              <VisibilityIcon
                className="visibility-icon"
                onClick={() => this.handleShowPin(true)}
              />
            )}
          </div>
          {this.state.message_final && (
            <span className="text-center error-text">
              {this.state.message_final}
            </span>
          )}
          <div className="switch-user">
            <Button
              onClick={() => {
                this.verifySecurityPin();
              }}
            >
              Confirm
            </Button>
            <div className="close-icon" onClick={this.handleLogout}>
              <CloseIcon />
            </div>
          </div>
          <div>
            <audio ref={this.audioRef} controls={false}>
              <source src={notificationSound} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
      </div>
    );
  }
}

LockScreen.contextType = ThemeContext;
export default LockScreen;

// Customizable Area End
