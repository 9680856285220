import React from 'react';
// Customizable Area Start
import { profileImg1, profileImg2 } from "../../dashboard/src/assets";
import ActivityFeedController from './ActivityFeedController';
import { clip, send, smile } from "./assets";
import "./ActivityFeed.scss";
// Customizable Area End


// Customizable Area Start
class Chat extends ActivityFeedController {
  // Customizable Area Start
  async componentDidMount() {
    this.scrollToBottom();
  }


  scrollToBottom = () => {
    this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }

  LeftView = (props: any) => {
    return (
      <div className="chat-left-main-class ">
        <div className="chat-left-item-class" >
          {props.showImage && <img
            style={{ height: 36, width: 36, borderRadius: 18, alignSelf: 'flex-start' }}
            src={profileImg2}
            alt={'username'} />}

          <div style={{ marginTop: -5 }} className="chat-left-item-text-class">
            <p style={{ fontSize: 12, color: 'black', textAlign: 'left' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
          </div>
        </div>
        <p style={{ alignSelf: 'flex-start', marginLeft: props.showImage ? 70 : 40, fontSize: 11, color: '#AFBBC6', marginTop: -3 }}>16:00</p>
      </div>
    );
  }

  RightView = (props: any) => {
    return (
      <div className="chat-right-main-class">
        <div className="chat-right-item-class" >
          <div className="chat-item-text-class">
            <p style={{ fontSize: 12, color: 'white', textAlign: 'right' }}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing</p>
          </div>
          {props.showImage && <img
            style={{ height: 36, width: 36, borderRadius: 18, alignSelf: 'flex-start' }}
            src={profileImg1}
            alt={'username'}
          />}
        </div>
        <p style={{ alignSelf: 'flex-end', marginRight: props.showImage ? 60 : 30, fontSize: 11, color: '#AFBBC6' }}>16:00</p>
      </div>
    );
  }
  // Customizable Area End

  render() {
    return (
      <div style={{ height: '100%' }} className="chat-users-class">

        <div className="chat-heading-class">
          <p style={{ fontSize: 16, }}>Isobel Rhodes</p>
        </div>
        <div className="chat-data-class scroller">
          {this.state.userData.map((item: any, index: any) => {
            if (index % 2 == 0) {
              return <this.LeftView name={item.name} showImage={true} key={index} />
            } else {
              return <this.RightView name={item.name} showImage={true} key={index} />
            }
          })}
          <div ref={this.messagesEndRef} />
        </div>
        <div className='user-typing-class'>
          <p style={{ color: '#7B8793', fontSize: 12, backgroundColor: '#DBE5ED', paddingRight: 10, paddingLeft: 10, paddingTop: 5, paddingBottom: 5, borderRadius: 10, borderBottomLeftRadius: 0, marginLeft: 10 }}>Isobel is typing...</p>
        </div>
        <div style={{ width: '95%', position: 'relative', marginTop: 5, marginBottom: 20, alignSelf: 'center' }} className="add-post-class">
          <textarea value={this.state.currentValue} ref={this.textareaRef} onChange={this.handleChangeTextArea} placeholder="Enter your message here" className={`user-chat-input-text-area ${this.context.isDark ? "textfield-color" : ''}`} style={{ minHeight: 54, maxHeight: 200, borderRadius: 30, paddingRight: 120 }} />
          <div className="user-input-action-buttons">

            <div className="pointer-class" style={{ alignItems: 'center', justifyItems: 'center' }}>
              <input
                ref={this.uploadImgRef}
                style={{ display: 'none' }}
                type="file"
                accept="image/*"
                name="image-upload"
                id="input"
                data-test-id='file-input-handler'

              />
              <img
                style={{ height: 18, width: 18, marginRight: 10 }}
                src={smile}
              />
            </div>

            <div onClick={() => {
              this.uploadImgRef.current.click();
            }} className="pointer-class" style={{ alignItems: 'center', justifyItems: 'center' }}>
              <img
                style={{ height: 18, width: 18, marginRight: 10 }}
                src={clip}
              />
            </div>

            <div className="input-field-send-icon">
              <img
                style={{ height: 18, width: 18, alignSelf: 'center' }}
                src={send}
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
// Customizable Area End