/** @format */

import React from "react";
// Customizable Area Start
import UserProfileDetailController, {
	Props,
} from "./UserProfileDetailController";
import { Grid } from "@material-ui/core";
import "./UserActivity.scss";
import { profileImg, imageCube } from "./assets";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class UserProfileDetail extends UserProfileDetailController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	render() {
		return (
			<div className="activity-profile-detail">
				<div className="activity-user-detail">
					<div className="image-section">
						<img src={profileImg} alt="user" />
					</div>
					<div className="profile-section">
						<h2>Cleara Jensen</h2>
						<h4>@clearajensen</h4>
						<div className="follower-following">
							<div className="followers">
								<span>209</span>
								<span className="profile-text">Followers</span>
							</div>
							<div className="following">
								<span>209</span>
								<span className="profile-text">Following</span>
							</div>
						</div>
					</div>
				</div>
				<div className="floating-cube-image">
					<img src={imageCube} alt="icecube" />
				</div>
			</div>
		);
	}
}
export default UserProfileDetail;

// Customizable Area End
