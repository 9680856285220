/** @format */

import React from "react";
// Customizable Area Start
import { heartFilled } from "./assets";
import { imageCube } from "../../dashboard/src/assets";
import ActivityFeedController from "./ActivityFeedController";
// Customizable Area End

// Customizable Area Start
class FeedTab extends ActivityFeedController {
	render() {
		return (
			<div style={{ flex: 1 }} className="scroller scroller-width">
				{this.state.userData.map((item: any) => (
					<div
					key={item.name}
						style={{ alignItems: "center", justifyContent: "center" }}
						className="activity-item">
						<div
							style={{ width: "70%", alignSelf: "center" }}
							className="user-detail-card">
							<div className="chat-card-sec">
								<div className="profile-img-container">
									<img src={item.profileImg} alt={item.name} />
									<span className="dots" />
								</div>
								<div className="profile-detail">
									<h3>{item.name}</h3>
									<p>{item.status}</p>
								</div>
							</div>
						</div>
						<div style={{ width: "70%" }} className="image-cube">
							<img
								style={{ width: "100%" }}
								className="item-bg-img-class"
								src={imageCube}
								alt="cube"
							/>
							<div className="buttons-div-class">
								<div
									style={{ alignItems: "center", justifyItems: "center" }}
									className="like-button">
									<img
										style={{ height: 30, width: 30 }}
										src={heartFilled}
										alt={"like"}
									/>
								</div>
							</div>
						</div>
						<h5 style={{ width: "70%", alignSelf: "centre" }}>
							Lorem Ipsum is simply dummy text of the printing and
							typesetting industry. Lorem Ipsum has been the industry's
							standard.
						</h5>
						<div className="divider-class"> </div>
					</div>
				))}
			</div>
		);
	}
}

export default FeedTab;
// Customizable Area End
