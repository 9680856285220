/** @format */

import React from "react";
// Customizable Area Start
import UserActivityController, { Props } from "./UserActivityController";
import { Grid } from "@material-ui/core";
import "./UserActivity.scss";
import UserProfileDetail from "./UserProfileDetail.web";
import OwnedNft from "./OwnedNft.web";
import PlayerActivity from "./PlayerActivity.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

// Customizable Area Start
class UserActivity extends UserActivityController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	render() {
		return (
			<div className="faqs">
				<div className=" container-width">
					<Grid container>
						<Grid item md={7} lg={7} className="profile-nft-detail">
							<UserProfileDetail navigation={this.props.navigation} />

							<OwnedNft navigation={this.props.navigation} />
						</Grid>
						<Grid item md={5} lg={5}>
							<PlayerActivity />
						</Grid>
					</Grid>
				</div>
			</div>
		);
	}
}
export default UserActivity;

// Customizable Area End
