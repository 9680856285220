// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { profileImg1, profileImg2, profileImg3 } from "./assets";
import { createRef } from "react";

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    item?: any;
	context?:any;

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userData: any;
    isPlaying: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
// Customizable Area Start
const data = [
	{
		id: 1,
		profileImg: profileImg1,
		name: "Isobel Rhodes",
		status: "Online",
	},
	{
		id: 2,
		profileImg: profileImg2,
		name: "Gail Jones",
		status: "Online",
	},
	{
		id: 3,
		profileImg: profileImg3,
		name: "Dianne Kelley",
		status: "Online",
	},
	{
		id: 4,
		profileImg: profileImg2,
		name: "Joel Martinez",
		status: "Online",
	},
	{
		id: 5,
		profileImg: profileImg1,
		name: "Armando Carpenter",
		status: "Online",
	},
	{
		id: 6,
		profileImg: profileImg1,
		name: "Isobel Rhodes",
		status: "Online",
	},
	{
		id: 7,
		profileImg: profileImg2,
		name: "Gail Jones",
		status: "Online",
	},
	{
		id: 8,
		profileImg: profileImg3,
		name: "Dianne Kelley",
		status: "Online",
	},
	{
		id: 9,
		profileImg: profileImg2,
		name: "Joel Martinez",
		status: "Online",
	},
	{
		id: 10,
		profileImg: profileImg1,
		name: "Armando Carpenter",
		status: "Online",
	},
	{
		id: 11,
		profileImg: profileImg1,
		name: "Isobel Rhodes",
		status: "Online",
	},
	{
		id: 12,
		profileImg: profileImg2,
		name: "Gail Jones",
		status: "Online",
	},
	{
		id: 13,
		profileImg: profileImg3,
		name: "Dianne Kelley",
		status: "Online",
	},
	{
		id: 14,
		profileImg: profileImg2,
		name: "Joel Martinez",
		status: "Online",
	},
	{
		id: 15,
		profileImg: profileImg1,
		name: "Armando Carpenter",
		status: "Online",
	},
];
// Customizable Area End

export default class DashboardControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
      audioRef: any = null;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);


        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            userData: data,
            isPlaying: false
      };
      this.audioRef = createRef();
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    componentDidMount(): any {
        // Customizable Area Start
        this.handlePlayPause();
        // Customizable Area End  

    }
    // Customizable Area Start
    handleOpenModal () {
      this.setState({ userData: "" });
	}
   handlePlayPause = () => {
    if (this.audioRef.current) {
      if (this.state.isPlaying) {
        this.audioRef.current.pause();
        this.setState({ isPlaying: false });
      } else {
        this.audioRef.current.play();
        this.setState({ isPlaying: true });
      }
    }
  };
    // Customizable Area End  

    async receive(from: string, message: Message) {
        
        // Customizable Area Start
        // Customizable Area End
    }

}
